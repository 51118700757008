body.woocommerce.single-product {

	.product {
		display: flex;
		flex-wrap: wrap;

		margin: 0 auto;
		max-width: #{($row-width / 12) * 10};

		.images,
		.summary {
			flex-basis: 24rem;
			flex-grow: 1;
		}

		.images {
			padding: 1rem;

			@media (max-width: 950px) {
				order: 1;
				flex-basis: auto;
			}

			.woocommerce-main-image {
				position: relative;

				display: flex;
				align-items: flex-start;
				justify-content: center;
				margin: 0 0 1rem;

				img {
					display: block;

					max-width: 100%;
					height: auto;
				}

				.icon-wrapper {
					position: absolute;
					z-index: 10;
					top: 0;
					left: 0;

					display: flex;
					flex-direction: column;
				}

				.icon {
					display: block;
					height: 3.5rem;
					width: 3.5rem;

					background-size: contain;
					background-position: top left;
					background-repeat: no-repeat;
				}
			}

			.thumbnails {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;

				a {
					flex-basis: 6rem;

					display: flex;
					margin: 0 1rem 1rem;
					align-items: center;
					justify-content: center;
					max-height: 8rem;

					img {
						display: block;
						max-width: 100%;
						height: auto;
					}
				}
			}
		}

		.summary {
			color: #FFF;
			padding: 1rem;

			@media (max-width: 950px) {
				order: 0;
			}

			.product_title {
				display: block;
				margin: 0 0 1rem;

				font-family: $font-title;
				font-weight: bold;
				font-size: 3.5rem;
			}

			.product_author {
				margin: 1rem 0 0;
			}

			hr {
				margin: 2rem 0;
				border-color: #FFF;
			}

			p {
				margin: 0 0 1rem;
			}
			
			[itemprop="description"] {
				color: #999;
				font-size: 0.9em;
			}

			.price {
				color: $color-mustard;
				font-size: 2.25rem;
			}

			.variations {
				margin: 0 0 2rem;

				td.label {
					padding: 1rem 1rem 1rem 0;
					font-size: 1.1rem;
				}

				select {
					max-width: 100%;
					width: 100%;
					text-overflow: ellipsis;

					margin: 0 0 0.25rem;
				}

				.reset_variations {
					color: $color-woo-border;
					font-size: 0.9rem;
				}
			}

			.single_variation {
				padding: 0.5rem 0;
				margin: 0 1rem 0 0;

				.price {
					display: inline-block;
					padding: 0 0 2rem;

					color: $color-mustard;
					font-size: 2.25rem;
				}
			}

			.woocommerce-variation-add-to-cart {
				display: flex;

				.quantity {
					display: flex;

					&::before {
						display: flex;
						align-items: center;
						margin: 0 1rem 0 0;

						content: "Qty:";
					}
				}

				input[type="number"] {
					margin: 0 2rem 0 0;
					padding: 0.25rem;
					width: 100px;

					font-size: 1.4rem;
					text-align: center;
				}

				button[type="submit"] {
					@extend %button;
					margin: 0;

					&.disabled {
						background-color: #DDD;
						color: #999;
					}
				}
			}

			.cart:not(.variations_form) {
				display: flex;

				.quantity {
					display: flex;

					&::before {
						display: flex;
						align-items: center;
						margin: 0 1rem 0 0;

						content: "Qty:";
					}
				}

				input[type="number"] {
					margin: 0 2rem 0 0;
					padding: 0.25rem;
					width: 100px;

					font-size: 1.4rem;
					text-align: center;
				}

				button[type="submit"] {
					@extend %button;
					margin: 0;

					&.disabled {
						background-color: #DDD;
						color: #999;
					}
				}
			}
		}

		.related.products {
			width: 100%;
			order: 3;

			margin: 2rem 0 0;
			padding: 2rem 1rem 0;
			border-top: 1px solid $color-red;

			h2 {
				display: block;
				margin: 0 0 1rem;

				color: #FFF;
				font-family: $font-title;
				font-weight: bold;
				font-size: 2.625rem;
			}

			ul.products {
				display: flex;
				flex-wrap: wrap;

				> li.product {
					flex-basis: 14rem;
					padding: 1rem;

					@extend %product-tile;

					.buttons {
						flex-direction: column;

						.button:not(:last-of-type) {
							margin-bottom: 1rem;
						}
					}
				}
			}
		}

		&.product-type-variable .summary .price {
			color: $color-silver;
			font-size: 1.6rem;
		}

		&.product-type-variable .summary .woocommerce-variation-price .price {
			color: $color-mustard;
			font-size: 2.25rem;
		}
	}
}