%custom-checkbox {

	input[type="radio"],
	input[type="checkbox"] {
		display: none;
	}

	label {
		display: flex;

		&::before {
			display: block;
			margin: 0 1rem 0 0;
			width: 1.5rem;

			content: "\f096";
			font-size: 2rem;
			color: $color-woo-border;
			font-family: fontawesome;
		}
	}

	input[type="checkbox"]:checked ~ label::before {
		content: "\f046";
	}
}



body.woocommerce-checkout {

	.woocommerce > .woocommerce-info {
		margin: 0 0 1rem;

		text-align: center;
	}

	.woocommerce > .login {
		margin: 0 0 1rem;
		padding: 1rem;
		width: 100%;

		background-color: #FFF;
		border-radius: 3px;

		p {
			margin: 0 0 0.5rem;

			&:last-of-type {
				margin: 0;
			}
		}

		.button {
			@extend %button;
		}
	}

	.woocommerce > .checkout_coupon {
		display: flex;
		padding: 1rem;

		border: 1px solid $color-woo-border;

		.form-row {
			display: flex;
			margin: 0;
			float: none;
		}

		.clear {
			display: none;
		}

		.button {
			@extend %button;
			margin: 0;
		}
	}

	$col-break: 860px;
	.col2-set {
		display: flex;
		flex-wrap: wrap;
		margin: 0 0 2rem;

		@media (max-width: $col-break) {
			flex-direction: column;
		}

		> div {
			flex-grow: 1;
			flex-basis: 1rem;

			@media (max-width: $col-break) {
				flex-basis: auto;
			}
		}

		.col-1 {
			padding-right: 1rem;

			@media (max-width: $col-break) {
				margin-bottom: 1rem;
				padding-right: 0;
			}
		}

		.col-2 {
			padding-left: 1rem;

			@media (max-width: $col-break) {
				padding-left: 0;
			}
		}
	}

	.form-row {
		margin: 0 0 0.5rem;

		&.form-row-first,
		&.form-row-last {
			float: left;
			width: 50%;

			@media (max-width: 540px) {
				float: none;
				width: 100%;
			}
		}

		&.form-row-first {
			padding-right: 0.5rem;

			@media (max-width: 540px) {
				padding-right: 0;
			}
		}

		&.form-row-last {
			padding-left: 0.5rem;

			@media (max-width: 540px) {
				padding-left: 0;
			}
		}

		label {
			display: block;
			font-family: $font-title;
		}

		textarea,
		input[type="text"],
		input[type="tel"],
		input[type="password"],
		input[type="email"] {
			display: block;
			width: 100%;
		}

		&.woocommerce-invalid {
			textarea,
			input[type="text"],
			input[type="tel"],
			input[type="password"],
			input[type="email"] {
				border-color: $color-error;
				background-color: rgba($color-error, 0.5);
			}
		}
	}

	.select2-container .select2-choice {
		border-color: $color-woo-border;
	}

	.select2-container .select2-choice > .select2-chosen {
		padding: 0.5rem;
	}

	.form-row.create-account {
		@extend %custom-checkbox;
	}


	.woocommerce-checkout-payment#payment {

		.payment_methods {
			margin: 0 0 1rem;
			padding: 1rem 0 0;

			border: 0;

			> li {
				display: block;
				margin: 0;
				padding: 0;

				&::before {
					display: none;
				}

				&:not(:last-of-type) {
					margin-bottom: 1rem;
				}

				input[type="radio"] {
					display: none;
				}

				> label {
					display: flex;
					align-items: center;
					padding: 1rem;

					cursor: pointer;
					font-size: 1.2rem;

					transition: all 0.3s ease;
					border: 1px solid $color-woo-border;

					&::before {
						display: block;
						width: 1.5rem;
						transition: all 0.2s ease;

						content: "\f10c";
						font-family: fontawesome;
						color: $color-woo-border;
					}

					&:hover {
						background-color: rgba($color-woo-border, 0.05);
					}

					img {
						display: inline-block;
						margin: 0 0.5rem;
					}
				}

				input:checked + label {
					background-color: rgba($color-woo-border, 0.1);

					&::before {
						content: "\f192";
						color: $color-woo-border;
					}
				}

				div.payment_box {
					margin: 0;
					padding: 0.5rem 1rem;

					border: 1px solid $color-woo-border;
					border-top: 0;

					background-color: rgba($color-woo-border, 0.3);

					p {
						margin: 0;
					}
				}
			}
		}

		.place-order {

			.button {
				@extend %button;
				display: block;
				padding: 1rem 3rem;
				margin: 0 auto;
				font-size: 1.4rem;
			}
		}
	}
}


body.woocommerce-order-received {

	.shop_table {
		margin: 0 0 2rem;

		td {
			padding: 0.5rem;
		}

		tbody th,
		tbody td {
			border-bottom: 1px solid $color-woo-border;
		}

		tbody th {
			border-right: 1px solid rgba($color-woo-border, 0.2);
		}
	}

	@media (max-width: 840px) {
		table.shop_table tbody td {
			margin: 0 0 1rem;
			padding: 1rem !important;
		}
		table.shop_table tbody th {
			padding: 0.5rem;
			border-right: 0;
			border-bottom: 0;
		}

		table.shop_table.order_details tfoot tr th {
			padding-bottom: 0;
			text-align: left;
			font-size: 0.8em;
			color: $color-silver;
		}
		table.shop_table.order_details tfoot tr:not(:last-of-type) td {
			margin: 0 0 0.5rem;
		}
	}
}


#ship-to-different-address {
	@extend %custom-checkbox;
}