gecko-slideshow {
	display: block;
	position: relative;
	z-index: 1;

	&:not([data-bound]) {
		visibility: hidden;
	}

	> .viewport {
		width: 100%;
		height: 600px;

		> .slides {
			position: relative;
			width: 100%;
			height: 100%;
		}
	}
}