body {

}
/* START Make the cart table responsive */
/* http://css-tricks.com/responsive-data-tables/ */

$cart-padding-left: 40%;

@media screen and (max-width: 840px) {

/* stylelint-disable */

/* Force table to not be like tables anymore */
.woocommerce-page table.shop_table, 
.woocommerce-page table.shop_table thead, 
.woocommerce-page table.shop_table tbody, 
.woocommerce-page table.shop_table tfoot, 
.woocommerce-page table.shop_table th, 
.woocommerce-page table.shop_table td, 
.woocommerce-page table.shop_table tr { 
	display: block;
}

/* Hide table headers (but not display: none;, for accessibility) */
.woocommerce-page table.shop_table thead tr { 
	position: absolute;
	top: -9999px;
	left: -9999px;
}

.woocommerce-page table.shop_table td {
	/* Behave  like a "row" */
	border: 1px solid $color-woo-border;
	position: relative;
	padding-left: calc(#{$cart-padding-left} + 1rem) !important;
}

.woocommerce-page table.shop_table {
	border: none;

	 tr.cart_item {
		border-right: 1px solid $color-woo-border;
	}
}

.woocommerce-page table.shop_table td.product-spacer {
	border-color: #FFF;
	height: 10px;
}

.woocommerce-page table.shop_table td:before { 
	/* Now like a table header */
	position: absolute;
	/* Top/left values mimic padding */
	top: 0;
	left: 0;

	display: flex;
	align-items: center;
	justify-content: flex-end;
	height: 100%;
	width: $cart-padding-left;

	text-align: right;
	padding-right: 1rem;
	white-space: nowrap;
	font-size: 1rem;
	color: $color-silver;
}

.woocommerce-page table.shop_table tr.cart_item > td:last-child {
	margin: 0 0 2rem;
	border-bottom: 1px solid $color-woo-border;
	border-right: 0;
}

/*
Label the data
*/
.woocommerce-page table.shop_table td.product-remove {
	padding-top: 0;
	padding-bottom: 0;

	.remove {
		font-size: 1.4rem;
		vertical-align: top;

		&::after {
			display: inline-block;
			margin: 0 0 0 0.5rem;
			content: " Remove";
		}
	}
}

.woocommerce-page table.shop_table td.product-remove,
.woocommerce-page table.shop_table td.product-name,
.woocommerce-page table.shop_table td.product-thumbnail {
	padding-left: 1rem !important;
	text-align: center !important;

	&:before {
		// content: "IMAGE";
	}
}

.woocommerce-page table.shop_table td.product-name a {
	font-size: 1.6rem;
}

.woocommerce-page table.shop_table td.product-price {
	text-align: left;

	&:before {
		content: "PRICE";
	}
}

.woocommerce-page table.shop_table td.product-quantity:before {
	content: "QUANTITY";
}

.woocommerce-page table.shop_table td.product-subtotal:before {
	content: "SUBTOTAL";
}

.woocommerce-page table.shop_table td.product-total:before {
	content: "TOTAL";
}

.woocommerce .quantity, 
.woocommerce #content .quantity, 
.woocommerce-page .quantity, 
.woocommerce-page #content .quantity {
	margin: 0;
}

.woocommerce-page table.cart td.actions, 
.woocommerce-page #content table.cart td.actions {
	text-align: left;
	border: 0;
	width: 100%;
	padding-left: 0.5rem !important;

	.coupon {
		margin: 0 0 1rem;
		flex-direction: column;
		float: none;

		input {
			margin: 0.25rem 0;
			width: 100%;
		}
	}

	input[name="update_cart"].button {
		position: relative;
	}

	input {
		margin: 0.25rem 0;
		width: 100%;
	}
}

body.woocommerce-cart .cart-collaterals {

	&::after {
		display: block;
		content: "";
		clear: both;
	}

	.shop_table tbody tr:not(:last-of-type) {
		padding-bottom: 1rem;
		margin-bottom: 1rem;

		border-bottom: 1px solid $color-red;
	}

	.shop_table tbody tr.order-total {
		margin: 0 0 1rem;

		th,
		td {
			font-size: 1.8rem;
		}
	}

	.wc-proceed-to-checkout a {
		text-align: center;
		width: 100%;
	}

	.shop_table tbody tr th {
		color: $color-silver;
		text-transform: uppercase;
		font-size: 1rem;
	}
	.shop_table tbody tr td {
		// text-align: center;
		padding-right: 0.5rem;
		width: auto;
		text-align: right;

		ul > li {
			margin: 0 0 1rem;

			&::before {
				display: none;
			}
		}
	}
}

.woocommerce-checkout .shop_table.woocommerce-checkout-review-order-table tfoot {
	width: 100%;

	tr:not(:last-of-type) {
		padding-bottom: 1rem;
		margin-bottom: 1rem;

		border-bottom: 1px solid $color-red;
	}

	tr th {
		color: $color-silver;
		text-transform: uppercase;
		font-size: 1rem;
	}
	tr td {
		// text-align: center;
		padding-right: 0.5rem;
		width: auto;
		text-align: right;

		ul > li {
			margin: 0 0 1rem;

			&::before {
				display: none;
			}
		}
	}
}

.woocommerce-page table.cart td.actions .button.alt,
.woocommerce-page #content table.cart td.actions .button.alt {
	// float: left;
	// margin-top: 10px;
}

.woocommerce-page table.cart td.actions div, 
.woocommerce-page #content table.cart td.actions div,
.woocommerce-page table.cart td.actions input, 
.woocommerce-page #content table.cart td.actions input {
	margin-bottom: 10px;
}

.woocommerce-page .cart-collaterals .cart_totals {
	float: left;
	width: 100%;
	text-align: left;
}

.woocommerce-page .cart-collaterals .cart_totals th, 
.woocommerce-page .cart-collaterals .cart_totals td {
	border:0 !important;
}

.woocommerce-page .cart-collaterals .cart_totals table tr.cart-subtotal td,
.woocommerce-page .cart-collaterals .cart_totals table tr.shipping td,
.woocommerce-page .cart-collaterals .cart_totals table tr.total td {
	padding-left: 6px !important;
}

.woocommerce-page table.shop_table tr.cart-subtotal td,
.woocommerce-page table.shop_table tr.shipping td,
.woocommerce-page table.shop_table tr.total td,
.woocommerce-page table.shop_table.order_details tfoot th,
.woocommerce-page table.shop_table.order_details tfoot td {
	padding-left: 6px !important;
	border:0 !important;
}

.woocommerce-page table.shop_table tbody {
	padding-top: 10px;
}

.woocommerce .col2-set .col-1, 
.woocommerce-page .col2-set .col-1,
.woocommerce .col2-set .col-2, 
.woocommerce-page .col2-set .col-2,
.woocommerce form .form-row-first, 
.woocommerce form .form-row-last, 
.woocommerce-page form .form-row-first, 
.woocommerce-page form .form-row-last {
	float: none;
	width: 100%;
}

.woocommerce .order_details ul, 
.woocommerce-page .order_details ul,
.woocommerce .order_details, 
.woocommerce-page .order_details {
	padding:0;
}

.woocommerce .order_details li, 
.woocommerce-page .order_details li {
	clear: left;
	margin-bottom: 10px;
	border:0;
}

/* stylelint-enable */

}

/* END Make the cart table responsive */

body {

}