@charset "UTF-8";
@import url(//maxcdn.bootstrapcdn.com/font-awesome/4.6.3/css/font-awesome.min.css);
@import url(https://fonts.googleapis.com/css?family=Amatic+SC:400,700|Antic+Slab);
/**
 * Eric Meyer's Reset CSS v2.0 (http://meyerweb.com/eric/tools/css/reset/)
 * http://cssreset.com
 *
 * Improved by Gecko Designs to reset ALL the things!
 */
html, body, div, span, applet, object, iframe,
button, input, select, textarea,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary, main,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  background: none;
  vertical-align: baseline; }

html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section,
main {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

a {
  text-decoration: none;
  color: inherit; }

h1, h2, h3, h4, h5, h6 {
  text-decoration: none; }

main > .clamped-width {
  max-width: 92rem;
  margin: 0 auto; }

/* Full-width mixin (1.0.0)
 * Creates a full-width section
 * Side-effect: element must be position relative
 */
/* Grid Box (1.0.0)
 * Creates a variable-count and variable-gutter grid system.
 * Apply this class to all children and ensure that they are the only members of the grid container.
 * This system is much more flexible than Foundation, it should be used for grids instead of columns.
 */
/* Breakpoint Utilities (1.0.0)
 * Provides utilities for imlementing custom, flexible breakpoints.
 */
/* Touch Nav Detection (1.1.0)
 * Determines whether we should be using touch navigation or not.
 * Pass in 'false' to the argument if the mixin is being used in the root instead of in a block.
 */
/* Base Styles
 * The styles that go here should be site-global and not specific to any individual components.
 */
body {
  font-family: "Antic Slab", serif;
  font-size: 1.25rem;
  background-color: #5F5F5F; }

.button, .wysiwyg button,
.wysiwyg .button,
.wysiwyg input[type="button"],
.wysiwyg input[type="submit"], body.woocommerce-cart .shop_table .actions .button, body.woocommerce-cart .cart-collaterals .wc-proceed-to-checkout .button, body.woocommerce-checkout .woocommerce > .login .button, body.woocommerce-checkout .woocommerce > .checkout_coupon .button, body.woocommerce-checkout .woocommerce-checkout-payment#payment .place-order .button, body.woocommerce.single-product .product .summary .woocommerce-variation-add-to-cart button[type="submit"], body.woocommerce.single-product .product .summary .cart:not(.variations_form) button[type="submit"] {
  display: inline-block;
  margin: 0 0 1rem;
  padding: 1rem 3rem 1rem 3.5rem;
  text-transform: uppercase;
  text-decoration: none;
  color: #FFF;
  font-size: 1.6rem;
  font-weight: bold;
  font-family: "Amatic SC", sans-serif;
  letter-spacing: 1px;
  transition: all 0.2s ease-in-out;
  border: 1px solid #d59d30;
  background-color: #d59d30; }
  .button::after, .wysiwyg button::after,
  .wysiwyg .button::after,
  .wysiwyg input[type="button"]::after,
  .wysiwyg input[type="submit"]::after, body.woocommerce-cart .shop_table .actions .button::after, body.woocommerce-cart .cart-collaterals .wc-proceed-to-checkout .button::after, body.woocommerce-checkout .woocommerce > .login .button::after, body.woocommerce-checkout .woocommerce > .checkout_coupon .button::after, body.woocommerce-checkout .woocommerce-checkout-payment#payment .place-order .button::after, body.woocommerce.single-product .product .summary .woocommerce-variation-add-to-cart button[type="submit"]::after, body.woocommerce.single-product .product .summary .cart:not(.variations_form) button[type="submit"]::after {
    display: inline-block;
    margin: 0 0 0 0.5rem;
    padding: 0 0.5rem 0 0;
    content: "\f105";
    color: rgba(242, 180, 56, 0.8);
    font-family: fontawesome;
    transition: all 0.2s ease-in-out; }
  .button:hover, .wysiwyg button:hover,
  .wysiwyg input[type="button"]:hover,
  .wysiwyg input[type="submit"]:hover, body.woocommerce-cart .shop_table .actions .button:hover, body.woocommerce-cart .cart-collaterals .wc-proceed-to-checkout .button:hover, body.woocommerce-checkout .woocommerce > .login .button:hover, body.woocommerce-checkout .woocommerce > .checkout_coupon .button:hover, body.woocommerce-checkout .woocommerce-checkout-payment#payment .place-order .button:hover, body.woocommerce.single-product .product .summary .woocommerce-variation-add-to-cart button[type="submit"]:hover, body.woocommerce.single-product .product .summary .cart:not(.variations_form) button[type="submit"]:hover {
    background-color: #f2b438; }
    .button:hover::after, .wysiwyg button:hover::after,
    .wysiwyg input[type="button"]:hover::after,
    .wysiwyg input[type="submit"]:hover::after, body.woocommerce-cart .shop_table .actions .button:hover::after, body.woocommerce-cart .cart-collaterals .wc-proceed-to-checkout .button:hover::after, body.woocommerce-checkout .woocommerce > .login .button:hover::after, body.woocommerce-checkout .woocommerce > .checkout_coupon .button:hover::after, body.woocommerce-checkout .woocommerce-checkout-payment#payment .place-order .button:hover::after, body.woocommerce.single-product .product .summary .woocommerce-variation-add-to-cart button[type="submit"]:hover::after, body.woocommerce.single-product .product .summary .cart:not(.variations_form) button[type="submit"]:hover::after {
      padding: 0;
      margin: 0 0 0 1rem;
      color: #f5c668; }
  .button:not(:last-of-type), .wysiwyg button:not(:last-of-type),
  .wysiwyg input[type="button"]:not(:last-of-type),
  .wysiwyg input[type="submit"]:not(:last-of-type), body.woocommerce-cart .shop_table .actions .button:not(:last-of-type), body.woocommerce-cart .cart-collaterals .wc-proceed-to-checkout .button:not(:last-of-type), body.woocommerce-checkout .woocommerce > .login .button:not(:last-of-type), body.woocommerce-checkout .woocommerce > .checkout_coupon .button:not(:last-of-type), body.woocommerce-checkout .woocommerce-checkout-payment#payment .place-order .button:not(:last-of-type), body.woocommerce.single-product .product .summary .woocommerce-variation-add-to-cart button[type="submit"]:not(:last-of-type), body.woocommerce.single-product .product .summary .cart:not(.variations_form) button[type="submit"]:not(:last-of-type) {
    margin-right: 1rem;
    margin-bottom: 1rem; }
  .secondary.button, .wysiwyg button.secondary,
  .wysiwyg input.secondary[type="button"],
  .wysiwyg input.secondary[type="submit"], body.woocommerce-cart .shop_table .actions .secondary.button, body.woocommerce-cart .cart-collaterals .wc-proceed-to-checkout .secondary.button, body.woocommerce-checkout .woocommerce > .login .secondary.button, body.woocommerce-checkout .woocommerce > .checkout_coupon .secondary.button, body.woocommerce-checkout .woocommerce-checkout-payment#payment .place-order .secondary.button, body.woocommerce.single-product .product .summary .woocommerce-variation-add-to-cart button.secondary[type="submit"], body.woocommerce.single-product .product .summary .cart:not(.variations_form) button.secondary[type="submit"] {
    background-color: #FFF;
    color: #3f3f3f; }
    .secondary.button::after, .wysiwyg button.secondary::after,
    .wysiwyg input.secondary[type="button"]::after,
    .wysiwyg input.secondary[type="submit"]::after, body.woocommerce-cart .shop_table .actions .secondary.button::after, body.woocommerce-cart .cart-collaterals .wc-proceed-to-checkout .secondary.button::after, body.woocommerce-checkout .woocommerce > .login .secondary.button::after, body.woocommerce-checkout .woocommerce > .checkout_coupon .secondary.button::after, body.woocommerce-checkout .woocommerce-checkout-payment#payment .place-order .secondary.button::after, body.woocommerce.single-product .product .summary .woocommerce-variation-add-to-cart button.secondary[type="submit"]::after, body.woocommerce.single-product .product .summary .cart:not(.variations_form) button.secondary[type="submit"]::after {
      color: #e1e1e1; }
    .secondary.button:hover, .wysiwyg button.secondary:hover,
    .wysiwyg input.secondary[type="button"]:hover,
    .wysiwyg input.secondary[type="submit"]:hover, body.woocommerce-cart .shop_table .actions .secondary.button:hover, body.woocommerce-cart .cart-collaterals .wc-proceed-to-checkout .secondary.button:hover, body.woocommerce-checkout .woocommerce > .login .secondary.button:hover, body.woocommerce-checkout .woocommerce > .checkout_coupon .secondary.button:hover, body.woocommerce-checkout .woocommerce-checkout-payment#payment .place-order .secondary.button:hover, body.woocommerce.single-product .product .summary .woocommerce-variation-add-to-cart button.secondary[type="submit"]:hover, body.woocommerce.single-product .product .summary .cart:not(.variations_form) button.secondary[type="submit"]:hover {
      background-color: #fff2d9; }

hr {
  display: block;
  margin: 3.5rem 0;
  border: 0;
  border-bottom: 1px solid #fd0100; }

main.page.woocommerce.front ul.products li.dummy.product, body.woocommerce.single-product .product .related.products ul.products > li.dummy.product {
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  height: 0;
  visibility: hidden; }

.chosen-container {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  font-size: 1rem;
  text-align: left;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  .chosen-container .chosen-drop {
    position: absolute;
    z-index: 1010;
    top: 100%;
    left: -9999px;
    width: 100%;
    background: #575757;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23); }
  .chosen-container.chosen-with-drop .chosen-drop {
    left: 0; }
  .chosen-container a {
    cursor: pointer; }
  .chosen-container .search-choice .group-name,
  .chosen-container .chosen-single .group-name {
    margin-right: 4px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: normal;
    color: #999999; }
    .chosen-container .search-choice .group-name::after,
    .chosen-container .chosen-single .group-name::after {
      content: ":";
      padding-left: 2px;
      vertical-align: top; }

/* @end */
/* @group Single Chosen */
.chosen-container-single {
  height: 3rem; }
  .chosen-container-single .chosen-single {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    overflow: hidden;
    padding: 0 0 0 8px;
    height: 100%;
    background: #5F5F5F;
    background-clip: padding-box;
    color: #FFF;
    text-decoration: none;
    white-space: nowrap;
    line-height: 2rem; }
    .chosen-container-single .chosen-single span {
      display: block;
      overflow: hidden;
      padding-left: 6px;
      margin-right: 26px;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .chosen-container-single .chosen-single abbr {
      position: absolute;
      top: 6px;
      right: 26px;
      display: block;
      width: 12px;
      height: 12px;
      font-size: 1px; }
      .chosen-container-single .chosen-single abbr:hover {
        background-position: -42px -10px; }
    .chosen-container-single .chosen-single div {
      position: absolute;
      top: 0;
      right: 0;
      display: block;
      width: 22px;
      height: 100%; }
      .chosen-container-single .chosen-single div b {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        width: 100%;
        height: 100%; }
        .chosen-container-single .chosen-single div b::before {
          color: #d59d30;
          content: "\f0d7";
          font-family: fontawesome; }
  .chosen-container-single.chosen-disabled .chosen-single abbr:hover {
    background-position: -42px -10px; }
  .chosen-container-single .chosen-single-with-deselect span {
    margin-right: 38px; }
  .chosen-container-single .chosen-search {
    position: relative;
    z-index: 1010;
    margin: 0;
    padding: 3px 4px;
    white-space: nowrap; }
    .chosen-container-single .chosen-search input[type="text"] {
      margin: 1px 0;
      padding: 4px 20px 4px 5px;
      width: 100%;
      height: auto;
      outline: 0;
      border: 1px solid #aaa;
      background: #5F5F5F;
      font-size: 1em;
      font-family: sans-serif;
      line-height: normal;
      border-radius: 0; }
  .chosen-container-single.chosen-container-single-nosearch .chosen-search {
    position: absolute;
    left: -9999px; }
  .chosen-container-single .chosen-drop {
    margin-top: -1px;
    border-radius: 0;
    background-clip: padding-box; }

/* @end */
/* @group Results */
.chosen-container .chosen-results {
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 240px;
  color: #EEE;
  -webkit-overflow-scrolling: touch; }
  .chosen-container .chosen-results li {
    display: none;
    margin: 0;
    padding: 0.625rem 1rem;
    list-style: none;
    line-height: 1rem;
    word-wrap: break-word;
    -webkit-touch-callout: none; }
    .chosen-container .chosen-results li.active-result {
      display: list-item;
      cursor: pointer; }
    .chosen-container .chosen-results li.result-selected {
      display: list-item;
      color: #9A9A9A;
      cursor: default; }
    .chosen-container .chosen-results li.disabled-result {
      display: list-item;
      color: #EEE;
      cursor: default; }
    .chosen-container .chosen-results li.highlighted {
      background-color: #6c6c6c;
      color: #FFF; }
    .chosen-container .chosen-results li.no-results {
      padding: 1rem;
      display: list-item;
      background: #5F5F5F;
      color: #EEE; }
      .chosen-container .chosen-results li.no-results span {
        color: #fd0100; }
    .chosen-container .chosen-results li.group-result {
      display: list-item;
      font-weight: bold;
      cursor: default; }
    .chosen-container .chosen-results li.group-option {
      padding-left: 15px; }
    .chosen-container .chosen-results li em {
      font-style: normal;
      text-decoration: underline; }

/* @end */
/* @group Multi Chosen */
.chosen-container-multi .chosen-choices {
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0 1.75rem 0.5rem 0.5rem;
  width: 100%;
  height: auto;
  min-height: 100%;
  border: 0;
  background-color: #d59d30;
  cursor: pointer; }
  .chosen-container-multi .chosen-choices::after {
    position: absolute;
    top: 10px;
    right: 10px;
    content: "\f0d7";
    color: #CDCDCD;
    font-size: 1.5rem;
    font-family: fontawesome;
    cursor: pointer; }
  .chosen-container-multi .chosen-choices li {
    float: left;
    list-style: none; }
    .chosen-container-multi .chosen-choices li.search-field {
      margin: 0;
      padding: 0;
      white-space: nowrap; }
      .chosen-container-multi .chosen-choices li.search-field input[type="text"] {
        display: inline-block;
        margin: 0.375rem 0 0;
        padding: 0.5rem 0 0;
        outline: 0;
        background: transparent;
        color: #999;
        font-size: 100%;
        font-family: sans-serif;
        line-height: normal;
        border: 0;
        box-shadow: none;
        border-radius: 0;
        cursor: pointer; }
    .chosen-container-multi .chosen-choices li.search-choice {
      position: relative;
      margin: 0.5rem 0.5rem 0 0;
      padding: 0.5rem 1.5rem 0.5rem 0.5rem;
      max-width: 100%;
      background-color: #fd0100;
      color: #EEE;
      line-height: 13px; }
      .chosen-container-multi .chosen-choices li.search-choice span {
        word-wrap: break-word; }
      .chosen-container-multi .chosen-choices li.search-choice .search-choice-close {
        position: absolute;
        right: 6px;
        top: 9px;
        display: block;
        width: 12px;
        height: 12px;
        background: url("../images/chosen-sprite.png") -42px 1px no-repeat;
        font-size: 1px; }
        .chosen-container-multi .chosen-choices li.search-choice .search-choice-close:hover {
          background-position: -42px -10px; }
    .chosen-container-multi .chosen-choices li.search-choice-disabled {
      padding-right: 0.5rem;
      background: transparent;
      text-transform: uppercase;
      color: #DDD; }
    .chosen-container-multi .chosen-choices li.search-choice-focus {
      background: #d4d4d4; }
      .chosen-container-multi .chosen-choices li.search-choice-focus .search-choice-close {
        background-position: -42px -10px; }

/* @end */
/* @group Active  */
.chosen-container-active.chosen-with-drop .chosen-single div {
  border-left: none;
  background: transparent; }
  .chosen-container-active.chosen-with-drop .chosen-single div b {
    background-position: -18px 2px; }

/* @end */
/* @group Disabled Support */
.chosen-disabled {
  opacity: 0.5;
  cursor: default; }
  .chosen-disabled .chosen-single,
  .chosen-disabled .chosen-choices .search-choice .search-choice-close {
    cursor: default; }

gecko-blackout {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease-in-out, visibility 0s linear 0.3s; }
  gecko-blackout:not([hidden]) {
    transition-delay: 0s; }
  gecko-blackout[hidden] {
    visibility: hidden;
    opacity: 0; }

gecko-slide {
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  transition: opacity 1s ease-in-out;
  opacity: 0; }
  gecko-slide[data-state="in"] {
    z-index: 2;
    opacity: 1; }

gecko-slideshow-controls {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  color: white;
  pointer-events: none; }
  gecko-slideshow-controls > * {
    pointer-events: all; }
  gecko-slideshow-controls .dots {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    left: 0;
    right: 0;
    top: calc(100% - 40px - 0.5rem); }
    gecko-slideshow-controls .dots > .dot {
      -ms-flex-negative: 0;
      flex-shrink: 0;
      width: 0.9rem;
      height: 0.9rem;
      margin: 0.5rem;
      cursor: pointer;
      border-radius: 100%;
      background-color: white;
      box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.2);
      opacity: 0.5;
      transition: opacity 0.2s ease-in-out; }
      gecko-slideshow-controls .dots > .dot[data-active] {
        opacity: 1; }
  gecko-slideshow-controls .left, gecko-slideshow-controls .right {
    position: absolute;
    transform: translateY(-50%);
    transition: transform 0.2s ease-in-out; }
  gecko-slideshow-controls .left {
    text-shadow: -1px 1px 1px black;
    left: 0;
    top: 50%; }
    gecko-slideshow-controls .left {
      display: -ms-inline-flexbox;
      display: inline-flex;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: center;
      justify-content: center;
      width: 1.2em;
      height: 1.2em;
      color: white;
      font-size: 4rem; }
      gecko-slideshow-controls .left::before {
        font-family: FontAwesome;
        content: ""; }
    gecko-slideshow-controls .left:active {
      transform: translateY(-50%) translateX(-6px); }
  gecko-slideshow-controls .right {
    text-shadow: 1px 1px 1px black;
    right: 0;
    top: 50%; }
    gecko-slideshow-controls .right {
      display: -ms-inline-flexbox;
      display: inline-flex;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: center;
      justify-content: center;
      width: 1.2em;
      height: 1.2em;
      color: white;
      font-size: 4rem; }
      gecko-slideshow-controls .right::before {
        font-family: FontAwesome;
        content: ""; }
    gecko-slideshow-controls .right:active {
      transform: translateY(-50%) translateX(6px); }

gecko-slideshow {
  display: block;
  position: relative;
  z-index: 1; }
  gecko-slideshow:not([data-bound]) {
    visibility: hidden; }
  gecko-slideshow > .viewport {
    width: 100%;
    height: 600px; }
    gecko-slideshow > .viewport > .slides {
      position: relative;
      width: 100%;
      height: 100%; }

select {
  padding: 0.5rem;
  color: #FFF;
  background-color: #5F5F5F; }

textarea,
input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]) {
  display: block;
  padding: 0.5rem;
  width: 100%;
  font-size: 1.2em;
  border: 0;
  box-shadow: none;
  outline: none;
  background-color: #fff2d9;
  transition: all 0.2s ease-in-out; }
  textarea:focus,
  input:focus:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]) {
    background-color: #FFF; }

input[type="radio"] {
  display: inline-block;
  margin: 0 0.5rem 0 0; }

.wysiwyg .gform_wrapper ul,
.wysiwyg .gform_wrapper .chosen-results,
.gform_wrapper ul,
.gform_wrapper .chosen-results {
  list-style: none;
  margin: 0; }
  .wysiwyg .gform_wrapper ul li::before,
  .wysiwyg .gform_wrapper .chosen-results li::before,
  .gform_wrapper ul li::before,
  .gform_wrapper .chosen-results li::before {
    display: none; }

.wysiwyg .gform_wrapper .gform_heading,
.gform_wrapper .gform_heading {
  margin: 0 0 1rem; }
  .wysiwyg .gform_wrapper .gform_heading .gform_title,
  .gform_wrapper .gform_heading .gform_title {
    display: block;
    margin: 0 0 0.25rem;
    font-size: 2rem;
    color: #1688ec; }
  .wysiwyg .gform_wrapper .gform_heading .gform_description,
  .gform_wrapper .gform_heading .gform_description {
    font-size: 0.9em; }

.wysiwyg .gform_wrapper .validation_error,
.gform_wrapper .validation_error {
  margin: 0 0 1rem;
  padding: 1rem;
  color: #fd0100;
  background-color: rgba(253, 1, 0, 0.1);
  border-radius: 3px;
  border: 1px solid #fd0100; }

.wysiwyg .gform_wrapper .gform_body .gform_fields,
.gform_wrapper .gform_body .gform_fields {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -1rem;
  list-style: none; }
  .wysiwyg .gform_wrapper .gform_body .gform_fields .gfield,
  .gform_wrapper .gform_body .gform_fields .gfield {
    margin: 0 0 1.5rem;
    padding: 0 1rem;
    width: 100%; }
    .wysiwyg .gform_wrapper .gform_body .gform_fields .gfield.half-col,
    .gform_wrapper .gform_body .gform_fields .gfield.half-col {
      width: 50%; }
      @media (max-width: 640px) {
        .wysiwyg .gform_wrapper .gform_body .gform_fields .gfield.half-col,
        .gform_wrapper .gform_body .gform_fields .gfield.half-col {
          width: 100%; } }
    .wysiwyg .gform_wrapper .gform_body .gform_fields .gfield.third-col,
    .gform_wrapper .gform_body .gform_fields .gfield.third-col {
      width: 33.3%; }
      @media (max-width: 640px) {
        .wysiwyg .gform_wrapper .gform_body .gform_fields .gfield.third-col,
        .gform_wrapper .gform_body .gform_fields .gfield.third-col {
          width: 100%; } }
    .wysiwyg .gform_wrapper .gform_body .gform_fields .gfield.gfield_visibility_,
    .gform_wrapper .gform_body .gform_fields .gfield.gfield_visibility_ {
      margin-top: 0;
      margin-bottom: 0;
      height: 0;
      overflow: hidden;
      visibility: hidden; }
    .wysiwyg .gform_wrapper .gform_body .gform_fields .gfield .gfield_label,
    .gform_wrapper .gform_body .gform_fields .gfield .gfield_label {
      display: block;
      margin: 0 0 0.5rem;
      font-family: "Amatic SC", sans-serif;
      font-size: 1.8rem;
      text-transform: uppercase; }
      .wysiwyg .gform_wrapper .gform_body .gform_fields .gfield .gfield_label .gfield_required,
      .gform_wrapper .gform_body .gform_fields .gfield .gfield_label .gfield_required {
        display: inline-block;
        margin: 0 0 0 0.125rem;
        color: #fd0100; }
    .wysiwyg .gform_wrapper .gform_body .gform_fields .gfield .validation_message,
    .gform_wrapper .gform_body .gform_fields .gfield .validation_message {
      padding: 0.4rem 0.4rem 0.2rem;
      background-color: rgba(253, 1, 0, 0.15);
      border-radius: 0 0 3px 3px;
      border: 1px solid #fd0100;
      border-top: 0;
      color: #fd0100;
      font-size: 0.7em;
      text-transform: uppercase; }
    .wysiwyg .gform_wrapper .gform_body .gform_fields .gfield .ginput_container_address,
    .gform_wrapper .gform_body .gform_fields .gfield .ginput_container_address {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      margin: 0 -0.5rem; }
      .wysiwyg .gform_wrapper .gform_body .gform_fields .gfield .ginput_container_address span,
      .gform_wrapper .gform_body .gform_fields .gfield .ginput_container_address span {
        display: block;
        padding: 0 0.5rem;
        margin: 0 0 0.5rem;
        width: 100%; }
        .wysiwyg .gform_wrapper .gform_body .gform_fields .gfield .ginput_container_address span.ginput_left, .wysiwyg .gform_wrapper .gform_body .gform_fields .gfield .ginput_container_address span.ginput_right,
        .gform_wrapper .gform_body .gform_fields .gfield .ginput_container_address span.ginput_left,
        .gform_wrapper .gform_body .gform_fields .gfield .ginput_container_address span.ginput_right {
          width: 50%; }
          @media (max-width: 640px) {
            .wysiwyg .gform_wrapper .gform_body .gform_fields .gfield .ginput_container_address span.ginput_left, .wysiwyg .gform_wrapper .gform_body .gform_fields .gfield .ginput_container_address span.ginput_right,
            .gform_wrapper .gform_body .gform_fields .gfield .ginput_container_address span.ginput_left,
            .gform_wrapper .gform_body .gform_fields .gfield .ginput_container_address span.ginput_right {
              width: 100%; } }
        .wysiwyg .gform_wrapper .gform_body .gform_fields .gfield .ginput_container_address span label,
        .gform_wrapper .gform_body .gform_fields .gfield .ginput_container_address span label {
          text-transform: uppercase;
          font-size: 0.6em; }
    .wysiwyg .gform_wrapper .gform_body .gform_fields .gfield li,
    .gform_wrapper .gform_body .gform_fields .gfield li {
      line-height: 1.5em; }
    .wysiwyg .gform_wrapper .gform_body .gform_fields .gfield.gfield_error input,
    .gform_wrapper .gform_body .gform_fields .gfield.gfield_error input {
      background-color: rgba(253, 1, 0, 0.1);
      border-color: #fd0100;
      border-bottom: 0;
      border-radius: 3px 3px 0 0; }

.wysiwyg .gform_wrapper .gform_footer,
.gform_wrapper .gform_footer {
  position: relative;
  text-align: center; }
  .wysiwyg .gform_wrapper .gform_footer .button,
  .gform_wrapper .gform_footer .button {
    outline: none;
    padding: 1rem 3.5rem; }
  .wysiwyg .gform_wrapper .gform_footer .gform_ajax_spinner,
  .gform_wrapper .gform_footer .gform_ajax_spinner {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    background-size: contain;
    background-position: center; }

body > footer {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 3rem 2rem;
  text-align: center;
  color: #FFF; }
  @media (max-width: 1024px) {
    body > footer {
      -ms-flex-direction: column;
      flex-direction: column;
      padding: 5rem 2rem 2rem; }
      body > footer div {
        margin: 0.5rem 0; } }
  body > footer a {
    color: #d59d30; }
  body > footer .colophon {
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 24rem;
    flex-basis: 24rem; }
    @media (max-width: 1024px) {
      body > footer .colophon {
        -ms-flex-preferred-size: auto;
        flex-basis: auto; } }
    body > footer .colophon.left-align {
      text-align: left; }
      @media (max-width: 1024px) {
        body > footer .colophon.left-align {
          text-align: center; } }
    body > footer .colophon.right-align {
      text-align: right; }
      @media (max-width: 1024px) {
        body > footer .colophon.right-align {
          text-align: center; } }
  body > footer .footer-seal {
    position: absolute;
    left: 50%;
    top: -5.625rem;
    transform: translateX(-50%);
    height: 189px;
    width: 196px;
    background-image: url(../images/footer-seal.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat; }
    @media (max-width: 1280px) {
      body > footer .footer-seal {
        height: 140px; } }

.footer-quote {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 4rem 2rem;
  background-image: url(../images/footer-quote-bg.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; }
  @media (max-width: 1024px) {
    .footer-quote {
      -ms-flex-direction: column;
      flex-direction: column; } }
  .footer-quote > div {
    width: 100%; }
    .footer-quote > div:first-child {
      margin-right: 100px; }
      @media (max-width: 1024px) {
        .footer-quote > div:first-child {
          margin-right: 0;
          margin: 0 0 2rem; } }
    .footer-quote > div:last-child {
      margin-left: 100px; }
      @media (max-width: 1024px) {
        .footer-quote > div:last-child {
          margin-left: 0;
          padding: 0 0 2rem; } }
  .footer-quote blockquote {
    position: relative;
    color: #3f3f3f;
    font-size: 1.8rem;
    line-height: 1.2em; }
    @media (max-width: 480px) {
      .footer-quote blockquote {
        font-size: 1.4rem; } }
    .footer-quote blockquote::before {
      position: absolute;
      top: 0;
      right: calc(100% + 1rem);
      display: block;
      height: 5rem;
      width: 5rem;
      content: "";
      background-image: url(../images/quote.svg);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat; }
      @media (max-width: 1024px) {
        .footer-quote blockquote::before {
          top: inherit;
          right: inherit;
          position: relative;
          margin: 0 auto; } }
    .footer-quote blockquote cite {
      display: block;
      padding: 0.5rem 0 0 1.5rem;
      font-size: 1.2rem; }
      @media (max-width: 480px) {
        .footer-quote blockquote cite {
          padding: 0.5rem 0 0 0;
          font-size: 1rem; } }
      .footer-quote blockquote cite::before {
        display: inline-block;
        margin: 0 0.5rem 0 0;
        content: "\2014"; }
  .footer-quote iframe {
    width: 100%; }

body > header.desktop {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid #000;
  background-image: url(../images/header-bg.jpg);
  background-color: #CDCDCD;
  background-position: center;
  background-repeat: repeat-x;
  background-size: cover; }
  @media screen and (max-width: 50rem) {
    body > header.desktop {
      display: none; } }
  html.touch body > header.desktop {
    display: none; }
  body > header.desktop .site-branding {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 1.5rem 0 1.5rem 2rem; }
    body > header.desktop .site-branding .logo {
      display: block;
      margin: 0 0 1rem;
      width: 314px;
      height: 86px;
      color: transparent;
      font-size: 0;
      background-image: url(../images/logo.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain; }
      @media (max-width: 1024px) {
        body > header.desktop .site-branding .logo {
          width: 280px;
          background-position: left center; } }
    body > header.desktop .site-branding h2 {
      color: #000;
      font-family: "Amatic SC", sans-serif;
      font-size: 2.5rem;
      font-weight: bold;
      text-align: center;
      transition: all 0.2s ease-in-out;
      border-bottom: 1px solid transparent; }
      @media (max-width: 1024px) {
        body > header.desktop .site-branding h2 {
          font-size: 2rem; } }
    body > header.desktop .site-branding .logo:hover + a h2,
    body > header.desktop .site-branding h2:hover {
      border-bottom: 1px solid #fd0100; }
  body > header.desktop nav {
    display: -ms-flexbox;
    display: flex;
    background-color: rgba(0, 0, 0, 0.7); }
    body > header.desktop nav div {
      display: -ms-flexbox;
      display: flex; }
    body > header.desktop nav .menu {
      display: -ms-flexbox;
      display: flex;
      padding: 0 1rem; }
      body > header.desktop nav .menu.cart-menu {
        background-color: #000; }
        body > header.desktop nav .menu.cart-menu a {
          color: #DDD; }
          body > header.desktop nav .menu.cart-menu a:hover {
            color: #FFF; }
        body > header.desktop nav .menu.cart-menu .cart-count {
          display: inline-block;
          margin: 0 0 0 0.5rem;
          color: #fd0100; }
          body > header.desktop nav .menu.cart-menu .cart-count::before {
            display: inline-block;
            margin: 0 0.25rem 0 0;
            content: "\f07a";
            font-family: fontawesome; }
      body > header.desktop nav .menu > li {
        display: -ms-flexbox;
        display: flex;
        margin: 0 1rem; }
        @media (max-width: 1024px) {
          body > header.desktop nav .menu > li {
            margin: 0 0.5rem; } }
        body > header.desktop nav .menu > li > a {
          display: block;
          padding: 1.5rem 0 1rem;
          font-family: "Amatic SC", sans-serif;
          font-weight: bold;
          font-size: 2rem;
          color: #FFF;
          text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
          transition: all 0.2s ease-in-out;
          border-bottom: 0.75rem solid transparent; }
          body > header.desktop nav .menu > li > a:hover {
            border-bottom: 0.75rem solid #d59d30; }
          @media (max-width: 1024px) {
            body > header.desktop nav .menu > li > a {
              font-size: 1.6rem; } }
        body > header.desktop nav .menu > li.current-menu-item > a {
          color: #dcad52;
          border-bottom: 0.75rem solid #d59d30; }
        body > header.desktop nav .menu > li.facebook a {
          font-size: 0;
          color: transparent; }
          body > header.desktop nav .menu > li.facebook a::before {
            display: inline-block;
            padding: 0 0.5rem;
            color: #FFF;
            content: "\f09a";
            font-size: 2rem;
            font-family: fontawesome; }
            @media (max-width: 1024px) {
              body > header.desktop nav .menu > li.facebook a::before {
                font-size: 1.6rem; } }
        body > header.desktop nav .menu > li > .sub-menu {
          display: none; }

body > header.touch {
  display: none;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  color: #FFF;
  background-color: #5F5F5F; }
  @media screen and (max-width: 50rem) {
    body > header.touch {
      display: -ms-flexbox;
      display: flex; } }
  html.touch body > header.touch {
    display: -ms-flexbox;
    display: flex; }
  body > header.touch > .toggle-touch-menu {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    font-size: 2.5rem;
    padding: 0.2em;
    cursor: pointer; }
    body > header.touch > .toggle-touch-menu::before {
      font-family: FontAwesome;
      width: 1em;
      height: 1em;
      vertical-align: top;
      content: "\f0c9";
      -webkit-font-smoothing: antialiased;
      color: #d59d30; }
  body > header.touch > .logo {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center; }
    body > header.touch > .logo a {
      display: block;
      padding: 0.5rem;
      text-align: right;
      font-size: 1.8rem;
      font-family: "Amatic SC", sans-serif; }

body > aside.touch-menu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 105;
  width: 80vw;
  max-width: 25rem;
  height: 100vh;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  color: #FFF;
  font-family: "Amatic SC", sans-serif;
  font-size: 2rem;
  background-color: #5F5F5F;
  transition: left 0.3s ease-in-out;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23); }
  body > aside.touch-menu[hidden] {
    left: -100%; }
  body > aside.touch-menu nav {
    -ms-flex-positive: 1;
    flex-grow: 1;
    overflow-y: auto; }
    body > aside.touch-menu nav li {
      display: block; }
      body > aside.touch-menu nav li a {
        display: block;
        padding: 1rem;
        border-bottom: 1px solid #525252;
        transition: background-color 0.2s ease-in-out; }
        body > aside.touch-menu nav li a:focus, body > aside.touch-menu nav li a:hover {
          background-color: rgba(0, 0, 0, 0.2); }
        body > aside.touch-menu nav li a:active {
          background-color: rgba(0, 0, 0, 0.3); }
  body > aside.touch-menu footer {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding: 1rem;
    text-align: center;
    background-color: #000; }
    body > aside.touch-menu footer a {
      color: #DDD; }
      body > aside.touch-menu footer a:hover {
        color: #FFF; }
    body > aside.touch-menu footer .cart-count {
      display: inline-block;
      margin: 0 0 0 0.5rem;
      color: #fd0100; }
      body > aside.touch-menu footer .cart-count::before {
        display: inline-block;
        margin: 0 0.25rem 0 0;
        content: "\f07a";
        font-family: fontawesome; }

.wysiwyg {
  color: #FFF; }
  .wysiwyg h1, .wysiwyg h2, .wysiwyg h3, .wysiwyg h4, .wysiwyg h5, .wysiwyg h6 {
    margin: 0 0 2rem;
    line-height: 1.2em;
    font-family: "Amatic SC", sans-serif; }
  .wysiwyg h1 {
    font-size: 3.5rem; }
  .wysiwyg h2 {
    font-size: 3.125rem; }
  .wysiwyg h3 {
    font-size: 2.625rem; }
  .wysiwyg h4 {
    font-size: 2.25rem; }
  .wysiwyg h5 {
    font-size: 1.875rem; }
  .wysiwyg h6 {
    font-size: 1.625rem; }
  .wysiwyg p {
    line-height: 1.3;
    margin: 1rem 0; }
  .wysiwyg b, .wysiwyg strong {
    font-weight: bold; }
  .wysiwyg i, .wysiwyg em {
    font-style: italic; }
  .wysiwyg ul {
    list-style: none;
    margin: 0 0 2rem 1.5rem; }
    .wysiwyg ul > li ul {
      margin: 0 0 0 1.5rem; }
    .wysiwyg ul li {
      position: relative;
      line-height: 1.2rem; }
      .wysiwyg ul li::before {
        position: absolute;
        top: 0;
        right: 100%;
        display: block;
        height: 2rem;
        width: 2rem;
        content: "\f105";
        color: #1688ec;
        text-align: center;
        font-size: 1.2rem;
        line-height: 1rem;
        font-family: fontawesome; }
  .wysiwyg ol {
    counter-reset: ol; }
    .wysiwyg ol > li {
      counter-increment: ol; }
      .wysiwyg ol > li::before {
        content: counter(ol);
        margin-right: 0.5rem;
        color: #1688ec; }
    .wysiwyg ol ol {
      margin: 0 0 0 1.5rem; }
  .wysiwyg ul > li, .wysiwyg ol > li {
    margin: 0.75rem 0; }
  .wysiwyg a {
    color: #d59d30; }

main.page.front {
  padding: 4rem 0; }

.featured-product {
  position: relative;
  padding: 2rem;
  background-color: #FFF; }
  .featured-product::after {
    position: absolute;
    z-index: 10;
    top: 0;
    right: 0;
    display: block;
    height: 100%;
    width: 55%;
    content: "";
    background-image: url(../images/featured-product-bg.jpg);
    background-position: center right;
    background-repeat: no-repeat;
    background-size: cover; }
    @media (max-width: 1024px) {
      .featured-product::after {
        top: 50%;
        width: 100%;
        height: 50%;
        background-image: linear-gradient(to bottom, #FFF, rgba(255, 255, 255, 0.6) 40%, rgba(255, 255, 255, 0)), url(../images/featured-product-bg.jpg); } }
  .featured-product .content {
    position: relative;
    z-index: 20;
    width: 40%; }
    @media (max-width: 1024px) {
      .featured-product .content {
        width: 100%; } }
    .featured-product .content .title {
      display: inline-block;
      margin: 0 0 1rem;
      color: #1688ec;
      font-family: "Amatic SC", sans-serif;
      font-weight: bold;
      font-size: 4rem;
      border-bottom: 1px solid transparent;
      transition: all 0.2s ease-in-out; }
      .featured-product .content .title:hover {
        border-bottom: 1px solid #1688ec; }
    .featured-product .content p {
      margin: 0 0 1.5rem;
      color: #5F5F5F;
      font-size: 1.2rem;
      line-height: 1.3em; }

main.page {
  padding: 2rem 0 4rem;
  background-image: url(../images/triangles-bg.jpg);
  background-color: #333; }
  main.page .wysiwyg {
    padding: 2rem; }
    @media (max-width: 640px) {
      main.page .wysiwyg {
        padding: 2rem 1rem; } }

main.page.woocommerce.front {
  padding: 3rem 1rem; }
  main.page.woocommerce.front .page-title {
    color: #FFF;
    font-size: 3rem;
    font-family: "Amatic SC", sans-serif; }
    @media (max-width: 640px) {
      main.page.woocommerce.front .page-title {
        text-align: center;
        margin: 0 0 1rem; } }
  main.page.woocommerce.front .shop-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0 0 1rem;
    padding: 0 1rem; }
    @media (max-width: 640px) {
      main.page.woocommerce.front .shop-header {
        -ms-flex-direction: column;
        flex-direction: column; } }
    main.page.woocommerce.front .shop-header .sorting {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      color: #FFF; }
      @media (max-width: 480px) {
        main.page.woocommerce.front .shop-header .sorting > span {
          display: none; } }
      @media (max-width: 480px) {
        main.page.woocommerce.front .shop-header .sorting {
          -ms-flex-direction: column;
          flex-direction: column; } }
    main.page.woocommerce.front .shop-header form {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      margin: 0 1rem;
      height: 100%; }
      @media (max-width: 640px) {
        main.page.woocommerce.front .shop-header form {
          -ms-flex-positive: 1;
          flex-grow: 1;
          margin: 0 1rem 0 0; } }
      @media (max-width: 480px) {
        main.page.woocommerce.front .shop-header form {
          margin: 0 0 1rem; } }
    main.page.woocommerce.front .shop-header nav {
      display: -ms-flexbox;
      display: flex; }
      @media (max-width: 480px) {
        main.page.woocommerce.front .shop-header nav {
          display: none; } }
      main.page.woocommerce.front .shop-header nav a {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;
        justify-content: center;
        height: 3rem;
        width: 3rem;
        font-size: 1.6rem;
        line-height: 1rem;
        color: #d59d30;
        transition: all 0.2s ease-in-out;
        border: 2px solid #d59d30; }
        main.page.woocommerce.front .shop-header nav a:not(:last-of-type) {
          margin: 0 1rem 0 0; }
        main.page.woocommerce.front .shop-header nav a::before {
          display: inline-block;
          font-family: fontawesome;
          transition: all 0.2s ease-in-out;
          -webkit-font-smoothing: antialiased; }
        main.page.woocommerce.front .shop-header nav a.grid::before {
          content: "\f00a"; }
        main.page.woocommerce.front .shop-header nav a.list::before {
          content: "\f00b"; }
        main.page.woocommerce.front .shop-header nav a[data-status="active"] {
          color: #FFF;
          cursor: default;
          background-color: #d59d30; }
          main.page.woocommerce.front .shop-header nav a[data-status="active"]:hover {
            background-color: #d59d30; }
            main.page.woocommerce.front .shop-header nav a[data-status="active"]:hover::before {
              color: #FFF; }
        main.page.woocommerce.front .shop-header nav a:hover {
          background-color: rgba(213, 157, 48, 0.15); }
          main.page.woocommerce.front .shop-header nav a:hover::before {
            color: #f2b438; }
  main.page.woocommerce.front ul.products {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
    main.page.woocommerce.front ul.products li.product {
      padding: 2rem;
      -ms-flex-preferred-size: 20rem;
      flex-basis: 20rem; }
    main.page.woocommerce.front ul.products[data-view="list"] {
      -ms-flex-direction: column;
      flex-direction: column;
      max-width: 92rem;
      margin: 0 auto; }
      main.page.woocommerce.front ul.products[data-view="list"] li.product {
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
        -ms-flex-direction: row;
        flex-direction: row; }
        @media (max-width: 640px) {
          main.page.woocommerce.front ul.products[data-view="list"] li.product {
            -ms-flex-direction: column;
            flex-direction: column; } }
        main.page.woocommerce.front ul.products[data-view="list"] li.product .image-wrapper {
          margin: 0 2rem 0 0;
          width: 12rem;
          height: 12rem; }
          @media (max-width: 640px) {
            main.page.woocommerce.front ul.products[data-view="list"] li.product .image-wrapper {
              margin: 0 0 1rem;
              width: 100%; } }
          main.page.woocommerce.front ul.products[data-view="list"] li.product .image-wrapper img {
            max-height: 100%;
            width: auto; }
        main.page.woocommerce.front ul.products[data-view="list"] li.product .description {
          display: block; }
        main.page.woocommerce.front ul.products[data-view="list"] li.product .item-meta {
          width: 100%;
          text-align: left; }
          @media (max-width: 640px) {
            main.page.woocommerce.front ul.products[data-view="list"] li.product .item-meta {
              text-align: center; } }
        main.page.woocommerce.front ul.products[data-view="list"] li.product > a {
          display: -ms-flexbox;
          display: flex;
          -ms-flex-align: center;
          align-items: center; }
          @media (max-width: 640px) {
            main.page.woocommerce.front ul.products[data-view="list"] li.product > a {
              display: block; } }
          main.page.woocommerce.front ul.products[data-view="list"] li.product > a h3 {
            border-bottom: 1px solid transparent; }
          main.page.woocommerce.front ul.products[data-view="list"] li.product > a:hover h3 {
            border-bottom: 1px solid #1688ec; }
        main.page.woocommerce.front ul.products[data-view="list"] li.product .buttons {
          -ms-flex-negative: 0;
          flex-shrink: 0;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-direction: column;
          flex-direction: column;
          -ms-flex-align: center;
          align-items: center; }
          main.page.woocommerce.front ul.products[data-view="list"] li.product .buttons .button {
            margin: 0.5rem 0;
            width: 100%; }

.woocommerce-pagination {
  padding: 2rem 0 0; }
  .woocommerce-pagination .page-numbers {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center; }
    .woocommerce-pagination .page-numbers li * {
      display: block;
      margin: 0 0.5rem;
      padding: 1rem;
      color: #FFF;
      font-size: 1.6rem;
      font-family: "Amatic SC", sans-serif;
      font-weight: bold; }
    .woocommerce-pagination .page-numbers li span {
      color: rgba(205, 205, 205, 0.8);
      cursor: default;
      background-color: rgba(205, 205, 205, 0.2); }
    .woocommerce-pagination .page-numbers li a {
      background-color: #d59d30; }

body.login {
  background-color: #134282; }
  body.login div#login h1 a {
    background-image: url(../avatar.png);
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    width: 300px;
    height: 100px; }

body.woocommerce-account .woocommerce-MyAccount-navigation {
  border-bottom: 2px solid #d59d30; }
  body.woocommerce-account .woocommerce-MyAccount-navigation ul {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none; }
    body.woocommerce-account .woocommerce-MyAccount-navigation ul li {
      margin: 0;
      padding: 0; }
      body.woocommerce-account .woocommerce-MyAccount-navigation ul li::before {
        display: none; }
      body.woocommerce-account .woocommerce-MyAccount-navigation ul li a {
        display: block;
        padding: 0.5rem 1.5rem;
        background-color: rgba(213, 157, 48, 0.2); }
        body.woocommerce-account .woocommerce-MyAccount-navigation ul li a:hover {
          background-color: rgba(213, 157, 48, 0.3); }
      body.woocommerce-account .woocommerce-MyAccount-navigation ul li.is-active a {
        color: #FFF;
        background-color: #d59d30; }

body.woocommerce-cart > h1 {
  display: none; }

body.woocommerce-cart .shop_table tbody .cart_item .remove {
  display: -ms-flexbox;
  display: flex;
  margin: 0 auto;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 3rem;
  width: 3rem;
  text-align: center;
  font-size: 3rem;
  line-height: 3rem;
  color: #d59d30;
  border-radius: 3px; }
  body.woocommerce-cart .shop_table tbody .cart_item .remove:hover {
    background-color: #d59d30;
    color: #5F5F5F; }

body.woocommerce-cart .shop_table tbody .cart_item .product-thumbnail a {
  display: inline-block;
  padding: 0.25rem;
  border-radius: 3px;
  border: 1px solid #d59d30;
  background-color: #FFF; }
  body.woocommerce-cart .shop_table tbody .cart_item .product-thumbnail a img {
    display: block;
    margin: 0 auto; }

body.woocommerce-cart .shop_table .actions {
  position: relative; }
  body.woocommerce-cart .shop_table .actions .button {
    margin-bottom: 0; }
  body.woocommerce-cart .shop_table .actions > .coupon {
    display: -ms-flexbox;
    display: flex; }
    body.woocommerce-cart .shop_table .actions > .coupon label {
      display: none; }
    body.woocommerce-cart .shop_table .actions > .coupon input[type="text"] {
      width: auto; }
  body.woocommerce-cart .shop_table .actions input[name="update_cart"].button {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 0; }

body.woocommerce-cart .cart-collaterals {
  padding: 0.5rem 0 0; }
  body.woocommerce-cart .cart-collaterals h2 {
    display: none; }
  body.woocommerce-cart .cart-collaterals .shop_table {
    border: 0; }
    body.woocommerce-cart .cart-collaterals .shop_table tbody tr th {
      padding: 0.5rem;
      text-align: right; }
    body.woocommerce-cart .cart-collaterals .shop_table tbody tr td {
      padding: 0.5rem;
      width: 24rem; }
      body.woocommerce-cart .cart-collaterals .shop_table tbody tr td ul, body.woocommerce-cart .cart-collaterals .shop_table tbody tr td ol {
        margin: 0; }
  body.woocommerce-cart .cart-collaterals .wc-proceed-to-checkout {
    text-align: right; }

body.woocommerce-checkout .form-row.create-account input[type="radio"], #ship-to-different-address input[type="radio"], body.woocommerce-checkout .form-row.create-account input[type="checkbox"], #ship-to-different-address input[type="checkbox"] {
  display: none; }

body.woocommerce-checkout .form-row.create-account label, #ship-to-different-address label {
  display: -ms-flexbox;
  display: flex; }
  body.woocommerce-checkout .form-row.create-account label::before, #ship-to-different-address label::before {
    display: block;
    margin: 0 1rem 0 0;
    width: 1.5rem;
    content: "\f096";
    font-size: 2rem;
    color: #d59d30;
    font-family: fontawesome; }

body.woocommerce-checkout .form-row.create-account input[type="checkbox"]:checked ~ label::before, #ship-to-different-address input[type="checkbox"]:checked ~ label::before {
  content: "\f046"; }

body.woocommerce-checkout .woocommerce > .woocommerce-info {
  margin: 0 0 1rem;
  text-align: center; }

body.woocommerce-checkout .woocommerce > .login {
  margin: 0 0 1rem;
  padding: 1rem;
  width: 100%;
  background-color: #FFF;
  border-radius: 3px; }
  body.woocommerce-checkout .woocommerce > .login p {
    margin: 0 0 0.5rem; }
    body.woocommerce-checkout .woocommerce > .login p:last-of-type {
      margin: 0; }

body.woocommerce-checkout .woocommerce > .checkout_coupon {
  display: -ms-flexbox;
  display: flex;
  padding: 1rem;
  border: 1px solid #d59d30; }
  body.woocommerce-checkout .woocommerce > .checkout_coupon .form-row {
    display: -ms-flexbox;
    display: flex;
    margin: 0;
    float: none; }
  body.woocommerce-checkout .woocommerce > .checkout_coupon .clear {
    display: none; }
  body.woocommerce-checkout .woocommerce > .checkout_coupon .button {
    margin: 0; }

body.woocommerce-checkout .col2-set {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 0 2rem; }
  @media (max-width: 860px) {
    body.woocommerce-checkout .col2-set {
      -ms-flex-direction: column;
      flex-direction: column; } }
  body.woocommerce-checkout .col2-set > div {
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 1rem;
    flex-basis: 1rem; }
    @media (max-width: 860px) {
      body.woocommerce-checkout .col2-set > div {
        -ms-flex-preferred-size: auto;
        flex-basis: auto; } }
  body.woocommerce-checkout .col2-set .col-1 {
    padding-right: 1rem; }
    @media (max-width: 860px) {
      body.woocommerce-checkout .col2-set .col-1 {
        margin-bottom: 1rem;
        padding-right: 0; } }
  body.woocommerce-checkout .col2-set .col-2 {
    padding-left: 1rem; }
    @media (max-width: 860px) {
      body.woocommerce-checkout .col2-set .col-2 {
        padding-left: 0; } }

body.woocommerce-checkout .form-row {
  margin: 0 0 0.5rem; }
  body.woocommerce-checkout .form-row.form-row-first, body.woocommerce-checkout .form-row.form-row-last {
    float: left;
    width: 50%; }
    @media (max-width: 540px) {
      body.woocommerce-checkout .form-row.form-row-first, body.woocommerce-checkout .form-row.form-row-last {
        float: none;
        width: 100%; } }
  body.woocommerce-checkout .form-row.form-row-first {
    padding-right: 0.5rem; }
    @media (max-width: 540px) {
      body.woocommerce-checkout .form-row.form-row-first {
        padding-right: 0; } }
  body.woocommerce-checkout .form-row.form-row-last {
    padding-left: 0.5rem; }
    @media (max-width: 540px) {
      body.woocommerce-checkout .form-row.form-row-last {
        padding-left: 0; } }
  body.woocommerce-checkout .form-row label {
    display: block;
    font-family: "Amatic SC", sans-serif; }
  body.woocommerce-checkout .form-row textarea,
  body.woocommerce-checkout .form-row input[type="text"],
  body.woocommerce-checkout .form-row input[type="tel"],
  body.woocommerce-checkout .form-row input[type="password"],
  body.woocommerce-checkout .form-row input[type="email"] {
    display: block;
    width: 100%; }
  body.woocommerce-checkout .form-row.woocommerce-invalid textarea,
  body.woocommerce-checkout .form-row.woocommerce-invalid input[type="text"],
  body.woocommerce-checkout .form-row.woocommerce-invalid input[type="tel"],
  body.woocommerce-checkout .form-row.woocommerce-invalid input[type="password"],
  body.woocommerce-checkout .form-row.woocommerce-invalid input[type="email"] {
    border-color: #fd0100;
    background-color: rgba(253, 1, 0, 0.5); }

body.woocommerce-checkout .select2-container .select2-choice {
  border-color: #d59d30; }

body.woocommerce-checkout .select2-container .select2-choice > .select2-chosen {
  padding: 0.5rem; }

body.woocommerce-checkout .woocommerce-checkout-payment#payment .payment_methods {
  margin: 0 0 1rem;
  padding: 1rem 0 0;
  border: 0; }
  body.woocommerce-checkout .woocommerce-checkout-payment#payment .payment_methods > li {
    display: block;
    margin: 0;
    padding: 0; }
    body.woocommerce-checkout .woocommerce-checkout-payment#payment .payment_methods > li::before {
      display: none; }
    body.woocommerce-checkout .woocommerce-checkout-payment#payment .payment_methods > li:not(:last-of-type) {
      margin-bottom: 1rem; }
    body.woocommerce-checkout .woocommerce-checkout-payment#payment .payment_methods > li input[type="radio"] {
      display: none; }
    body.woocommerce-checkout .woocommerce-checkout-payment#payment .payment_methods > li > label {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      padding: 1rem;
      cursor: pointer;
      font-size: 1.2rem;
      transition: all 0.3s ease;
      border: 1px solid #d59d30; }
      body.woocommerce-checkout .woocommerce-checkout-payment#payment .payment_methods > li > label::before {
        display: block;
        width: 1.5rem;
        transition: all 0.2s ease;
        content: "\f10c";
        font-family: fontawesome;
        color: #d59d30; }
      body.woocommerce-checkout .woocommerce-checkout-payment#payment .payment_methods > li > label:hover {
        background-color: rgba(213, 157, 48, 0.05); }
      body.woocommerce-checkout .woocommerce-checkout-payment#payment .payment_methods > li > label img {
        display: inline-block;
        margin: 0 0.5rem; }
    body.woocommerce-checkout .woocommerce-checkout-payment#payment .payment_methods > li input:checked + label {
      background-color: rgba(213, 157, 48, 0.1); }
      body.woocommerce-checkout .woocommerce-checkout-payment#payment .payment_methods > li input:checked + label::before {
        content: "\f192";
        color: #d59d30; }
    body.woocommerce-checkout .woocommerce-checkout-payment#payment .payment_methods > li div.payment_box {
      margin: 0;
      padding: 0.5rem 1rem;
      border: 1px solid #d59d30;
      border-top: 0;
      background-color: rgba(213, 157, 48, 0.3); }
      body.woocommerce-checkout .woocommerce-checkout-payment#payment .payment_methods > li div.payment_box p {
        margin: 0; }

body.woocommerce-checkout .woocommerce-checkout-payment#payment .place-order .button {
  display: block;
  padding: 1rem 3rem;
  margin: 0 auto;
  font-size: 1.4rem; }

body.woocommerce-order-received .shop_table {
  margin: 0 0 2rem; }
  body.woocommerce-order-received .shop_table td {
    padding: 0.5rem; }
  body.woocommerce-order-received .shop_table tbody th,
  body.woocommerce-order-received .shop_table tbody td {
    border-bottom: 1px solid #d59d30; }
  body.woocommerce-order-received .shop_table tbody th {
    border-right: 1px solid rgba(213, 157, 48, 0.2); }

@media (max-width: 840px) {
  body.woocommerce-order-received table.shop_table tbody td {
    margin: 0 0 1rem;
    padding: 1rem !important; }
  body.woocommerce-order-received table.shop_table tbody th {
    padding: 0.5rem;
    border-right: 0;
    border-bottom: 0; }
  body.woocommerce-order-received table.shop_table.order_details tfoot tr th {
    padding-bottom: 0;
    text-align: left;
    font-size: 0.8em;
    color: #CDCDCD; }
  body.woocommerce-order-received table.shop_table.order_details tfoot tr:not(:last-of-type) td {
    margin: 0 0 0.5rem; } }

/* START Make the cart table responsive */
/* http://css-tricks.com/responsive-data-tables/ */
@media screen and (max-width: 840px) {
  /* stylelint-disable */
  /* Force table to not be like tables anymore */
  .woocommerce-page table.shop_table,
  .woocommerce-page table.shop_table thead,
  .woocommerce-page table.shop_table tbody,
  .woocommerce-page table.shop_table tfoot,
  .woocommerce-page table.shop_table th,
  .woocommerce-page table.shop_table td,
  .woocommerce-page table.shop_table tr {
    display: block; }
  /* Hide table headers (but not display: none;, for accessibility) */
  .woocommerce-page table.shop_table thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px; }
  .woocommerce-page table.shop_table td {
    /* Behave  like a "row" */
    border: 1px solid #d59d30;
    position: relative;
    padding-left: calc(40% + 1rem) !important; }
  .woocommerce-page table.shop_table {
    border: none; }
    .woocommerce-page table.shop_table tr.cart_item {
      border-right: 1px solid #d59d30; }
  .woocommerce-page table.shop_table td.product-spacer {
    border-color: #FFF;
    height: 10px; }
  .woocommerce-page table.shop_table td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0;
    left: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: end;
    justify-content: flex-end;
    height: 100%;
    width: 40%;
    text-align: right;
    padding-right: 1rem;
    white-space: nowrap;
    font-size: 1rem;
    color: #CDCDCD; }
  .woocommerce-page table.shop_table tr.cart_item > td:last-child {
    margin: 0 0 2rem;
    border-bottom: 1px solid #d59d30;
    border-right: 0; }
  /*
Label the data
*/
  .woocommerce-page table.shop_table td.product-remove {
    padding-top: 0;
    padding-bottom: 0; }
    .woocommerce-page table.shop_table td.product-remove .remove {
      font-size: 1.4rem;
      vertical-align: top; }
      .woocommerce-page table.shop_table td.product-remove .remove::after {
        display: inline-block;
        margin: 0 0 0 0.5rem;
        content: " Remove"; }
  .woocommerce-page table.shop_table td.product-remove,
  .woocommerce-page table.shop_table td.product-name,
  .woocommerce-page table.shop_table td.product-thumbnail {
    padding-left: 1rem !important;
    text-align: center !important; }
  .woocommerce-page table.shop_table td.product-name a {
    font-size: 1.6rem; }
  .woocommerce-page table.shop_table td.product-price {
    text-align: left; }
    .woocommerce-page table.shop_table td.product-price:before {
      content: "PRICE"; }
  .woocommerce-page table.shop_table td.product-quantity:before {
    content: "QUANTITY"; }
  .woocommerce-page table.shop_table td.product-subtotal:before {
    content: "SUBTOTAL"; }
  .woocommerce-page table.shop_table td.product-total:before {
    content: "TOTAL"; }
  .woocommerce .quantity,
  .woocommerce #content .quantity,
  .woocommerce-page .quantity,
  .woocommerce-page #content .quantity {
    margin: 0; }
  .woocommerce-page table.cart td.actions,
  .woocommerce-page #content table.cart td.actions {
    text-align: left;
    border: 0;
    width: 100%;
    padding-left: 0.5rem !important; }
    .woocommerce-page table.cart td.actions .coupon,
    .woocommerce-page #content table.cart td.actions .coupon {
      margin: 0 0 1rem;
      -ms-flex-direction: column;
      flex-direction: column;
      float: none; }
      .woocommerce-page table.cart td.actions .coupon input,
      .woocommerce-page #content table.cart td.actions .coupon input {
        margin: 0.25rem 0;
        width: 100%; }
    .woocommerce-page table.cart td.actions input[name="update_cart"].button,
    .woocommerce-page #content table.cart td.actions input[name="update_cart"].button {
      position: relative; }
    .woocommerce-page table.cart td.actions input,
    .woocommerce-page #content table.cart td.actions input {
      margin: 0.25rem 0;
      width: 100%; }
  body.woocommerce-cart .cart-collaterals::after {
    display: block;
    content: "";
    clear: both; }
  body.woocommerce-cart .cart-collaterals .shop_table tbody tr:not(:last-of-type) {
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid #fd0100; }
  body.woocommerce-cart .cart-collaterals .shop_table tbody tr.order-total {
    margin: 0 0 1rem; }
    body.woocommerce-cart .cart-collaterals .shop_table tbody tr.order-total th,
    body.woocommerce-cart .cart-collaterals .shop_table tbody tr.order-total td {
      font-size: 1.8rem; }
  body.woocommerce-cart .cart-collaterals .wc-proceed-to-checkout a {
    text-align: center;
    width: 100%; }
  body.woocommerce-cart .cart-collaterals .shop_table tbody tr th {
    color: #CDCDCD;
    text-transform: uppercase;
    font-size: 1rem; }
  body.woocommerce-cart .cart-collaterals .shop_table tbody tr td {
    padding-right: 0.5rem;
    width: auto;
    text-align: right; }
    body.woocommerce-cart .cart-collaterals .shop_table tbody tr td ul > li {
      margin: 0 0 1rem; }
      body.woocommerce-cart .cart-collaterals .shop_table tbody tr td ul > li::before {
        display: none; }
  .woocommerce-checkout .shop_table.woocommerce-checkout-review-order-table tfoot {
    width: 100%; }
    .woocommerce-checkout .shop_table.woocommerce-checkout-review-order-table tfoot tr:not(:last-of-type) {
      padding-bottom: 1rem;
      margin-bottom: 1rem;
      border-bottom: 1px solid #fd0100; }
    .woocommerce-checkout .shop_table.woocommerce-checkout-review-order-table tfoot tr th {
      color: #CDCDCD;
      text-transform: uppercase;
      font-size: 1rem; }
    .woocommerce-checkout .shop_table.woocommerce-checkout-review-order-table tfoot tr td {
      padding-right: 0.5rem;
      width: auto;
      text-align: right; }
      .woocommerce-checkout .shop_table.woocommerce-checkout-review-order-table tfoot tr td ul > li {
        margin: 0 0 1rem; }
        .woocommerce-checkout .shop_table.woocommerce-checkout-review-order-table tfoot tr td ul > li::before {
          display: none; }
  .woocommerce-page table.cart td.actions div,
  .woocommerce-page #content table.cart td.actions div,
  .woocommerce-page table.cart td.actions input,
  .woocommerce-page #content table.cart td.actions input {
    margin-bottom: 10px; }
  .woocommerce-page .cart-collaterals .cart_totals {
    float: left;
    width: 100%;
    text-align: left; }
  .woocommerce-page .cart-collaterals .cart_totals th,
  .woocommerce-page .cart-collaterals .cart_totals td {
    border: 0 !important; }
  .woocommerce-page .cart-collaterals .cart_totals table tr.cart-subtotal td,
  .woocommerce-page .cart-collaterals .cart_totals table tr.shipping td,
  .woocommerce-page .cart-collaterals .cart_totals table tr.total td {
    padding-left: 6px !important; }
  .woocommerce-page table.shop_table tr.cart-subtotal td,
  .woocommerce-page table.shop_table tr.shipping td,
  .woocommerce-page table.shop_table tr.total td,
  .woocommerce-page table.shop_table.order_details tfoot th,
  .woocommerce-page table.shop_table.order_details tfoot td {
    padding-left: 6px !important;
    border: 0 !important; }
  .woocommerce-page table.shop_table tbody {
    padding-top: 10px; }
  .woocommerce .col2-set .col-1,
  .woocommerce-page .col2-set .col-1,
  .woocommerce .col2-set .col-2,
  .woocommerce-page .col2-set .col-2,
  .woocommerce form .form-row-first,
  .woocommerce form .form-row-last,
  .woocommerce-page form .form-row-first,
  .woocommerce-page form .form-row-last {
    float: none;
    width: 100%; }
  .woocommerce .order_details ul,
  .woocommerce-page .order_details ul,
  .woocommerce .order_details,
  .woocommerce-page .order_details {
    padding: 0; }
  .woocommerce .order_details li,
  .woocommerce-page .order_details li {
    clear: left;
    margin-bottom: 10px;
    border: 0; }
  /* stylelint-enable */ }

/* END Make the cart table responsive */
body.woocommerce.single-product .product {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 76.66667rem; }
  body.woocommerce.single-product .product .images,
  body.woocommerce.single-product .product .summary {
    -ms-flex-preferred-size: 24rem;
    flex-basis: 24rem;
    -ms-flex-positive: 1;
    flex-grow: 1; }
  body.woocommerce.single-product .product .images {
    padding: 1rem; }
    @media (max-width: 950px) {
      body.woocommerce.single-product .product .images {
        -ms-flex-order: 1;
        order: 1;
        -ms-flex-preferred-size: auto;
        flex-basis: auto; } }
    body.woocommerce.single-product .product .images .woocommerce-main-image {
      position: relative;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: start;
      align-items: flex-start;
      -ms-flex-pack: center;
      justify-content: center;
      margin: 0 0 1rem; }
      body.woocommerce.single-product .product .images .woocommerce-main-image img {
        display: block;
        max-width: 100%;
        height: auto; }
      body.woocommerce.single-product .product .images .woocommerce-main-image .icon-wrapper {
        position: absolute;
        z-index: 10;
        top: 0;
        left: 0;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column; }
      body.woocommerce.single-product .product .images .woocommerce-main-image .icon {
        display: block;
        height: 3.5rem;
        width: 3.5rem;
        background-size: contain;
        background-position: top left;
        background-repeat: no-repeat; }
    body.woocommerce.single-product .product .images .thumbnails {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -ms-flex-pack: center;
      justify-content: center; }
      body.woocommerce.single-product .product .images .thumbnails a {
        -ms-flex-preferred-size: 6rem;
        flex-basis: 6rem;
        display: -ms-flexbox;
        display: flex;
        margin: 0 1rem 1rem;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;
        justify-content: center;
        max-height: 8rem; }
        body.woocommerce.single-product .product .images .thumbnails a img {
          display: block;
          max-width: 100%;
          height: auto; }
  body.woocommerce.single-product .product .summary {
    color: #FFF;
    padding: 1rem; }
    @media (max-width: 950px) {
      body.woocommerce.single-product .product .summary {
        -ms-flex-order: 0;
        order: 0; } }
    body.woocommerce.single-product .product .summary .product_title {
      display: block;
      margin: 0 0 1rem;
      font-family: "Amatic SC", sans-serif;
      font-weight: bold;
      font-size: 3.5rem; }
    body.woocommerce.single-product .product .summary .product_author {
      margin: 1rem 0 0; }
    body.woocommerce.single-product .product .summary hr {
      margin: 2rem 0;
      border-color: #FFF; }
    body.woocommerce.single-product .product .summary p {
      margin: 0 0 1rem; }
    body.woocommerce.single-product .product .summary [itemprop="description"] {
      color: #999;
      font-size: 0.9em; }
    body.woocommerce.single-product .product .summary .price {
      color: #d59d30;
      font-size: 2.25rem; }
    body.woocommerce.single-product .product .summary .variations {
      margin: 0 0 2rem; }
      body.woocommerce.single-product .product .summary .variations td.label {
        padding: 1rem 1rem 1rem 0;
        font-size: 1.1rem; }
      body.woocommerce.single-product .product .summary .variations select {
        max-width: 100%;
        width: 100%;
        text-overflow: ellipsis;
        margin: 0 0 0.25rem; }
      body.woocommerce.single-product .product .summary .variations .reset_variations {
        color: #d59d30;
        font-size: 0.9rem; }
    body.woocommerce.single-product .product .summary .single_variation {
      padding: 0.5rem 0;
      margin: 0 1rem 0 0; }
      body.woocommerce.single-product .product .summary .single_variation .price {
        display: inline-block;
        padding: 0 0 2rem;
        color: #d59d30;
        font-size: 2.25rem; }
    body.woocommerce.single-product .product .summary .woocommerce-variation-add-to-cart {
      display: -ms-flexbox;
      display: flex; }
      body.woocommerce.single-product .product .summary .woocommerce-variation-add-to-cart .quantity {
        display: -ms-flexbox;
        display: flex; }
        body.woocommerce.single-product .product .summary .woocommerce-variation-add-to-cart .quantity::before {
          display: -ms-flexbox;
          display: flex;
          -ms-flex-align: center;
          align-items: center;
          margin: 0 1rem 0 0;
          content: "Qty:"; }
      body.woocommerce.single-product .product .summary .woocommerce-variation-add-to-cart input[type="number"] {
        margin: 0 2rem 0 0;
        padding: 0.25rem;
        width: 100px;
        font-size: 1.4rem;
        text-align: center; }
      body.woocommerce.single-product .product .summary .woocommerce-variation-add-to-cart button[type="submit"] {
        margin: 0; }
        body.woocommerce.single-product .product .summary .woocommerce-variation-add-to-cart button[type="submit"].disabled {
          background-color: #DDD;
          color: #999; }
    body.woocommerce.single-product .product .summary .cart:not(.variations_form) {
      display: -ms-flexbox;
      display: flex; }
      body.woocommerce.single-product .product .summary .cart:not(.variations_form) .quantity {
        display: -ms-flexbox;
        display: flex; }
        body.woocommerce.single-product .product .summary .cart:not(.variations_form) .quantity::before {
          display: -ms-flexbox;
          display: flex;
          -ms-flex-align: center;
          align-items: center;
          margin: 0 1rem 0 0;
          content: "Qty:"; }
      body.woocommerce.single-product .product .summary .cart:not(.variations_form) input[type="number"] {
        margin: 0 2rem 0 0;
        padding: 0.25rem;
        width: 100px;
        font-size: 1.4rem;
        text-align: center; }
      body.woocommerce.single-product .product .summary .cart:not(.variations_form) button[type="submit"] {
        margin: 0; }
        body.woocommerce.single-product .product .summary .cart:not(.variations_form) button[type="submit"].disabled {
          background-color: #DDD;
          color: #999; }
  body.woocommerce.single-product .product .related.products {
    width: 100%;
    -ms-flex-order: 3;
    order: 3;
    margin: 2rem 0 0;
    padding: 2rem 1rem 0;
    border-top: 1px solid #fd0100; }
    body.woocommerce.single-product .product .related.products h2 {
      display: block;
      margin: 0 0 1rem;
      color: #FFF;
      font-family: "Amatic SC", sans-serif;
      font-weight: bold;
      font-size: 2.625rem; }
    body.woocommerce.single-product .product .related.products ul.products {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
      body.woocommerce.single-product .product .related.products ul.products > li.product {
        -ms-flex-preferred-size: 14rem;
        flex-basis: 14rem;
        padding: 1rem; }
        body.woocommerce.single-product .product .related.products ul.products > li.product .buttons {
          -ms-flex-direction: column;
          flex-direction: column; }
          body.woocommerce.single-product .product .related.products ul.products > li.product .buttons .button:not(:last-of-type) {
            margin-bottom: 1rem; }
  body.woocommerce.single-product .product.product-type-variable .summary .price {
    color: #CDCDCD;
    font-size: 1.6rem; }
  body.woocommerce.single-product .product.product-type-variable .summary .woocommerce-variation-price .price {
    color: #d59d30;
    font-size: 2.25rem; }

main.page.woocommerce.front ul.products li.product, body.woocommerce.single-product .product .related.products ul.products > li.product {
  -ms-flex-positive: 1;
  flex-grow: 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 1rem;
  text-align: center;
  color: #3f3f3f;
  background-color: #CDCDCD;
  transition: background-color 0.4s ease-in-out;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24); }
  main.page.woocommerce.front ul.products li.product:hover, body.woocommerce.single-product .product .related.products ul.products > li.product:hover {
    background-color: #d5d5d5; }
  main.page.woocommerce.front ul.products li.product .image-wrapper, body.woocommerce.single-product .product .related.products ul.products > li.product .image-wrapper {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 0 0.5rem;
    height: 20rem;
    max-width: 100%; }
    main.page.woocommerce.front ul.products li.product .image-wrapper .onsale, body.woocommerce.single-product .product .related.products ul.products > li.product .image-wrapper .onsale {
      position: absolute;
      bottom: 0;
      right: 0;
      padding: 0.5rem;
      color: #FFF;
      font-family: "Amatic SC", sans-serif;
      font-size: 2rem;
      border-radius: 1000px;
      box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
      background-color: #fd0100; }
    main.page.woocommerce.front ul.products li.product .image-wrapper img, body.woocommerce.single-product .product .related.products ul.products > li.product .image-wrapper img {
      display: block;
      height: auto;
      max-width: 100%;
      transition: all 0.1s ease-in-out;
      box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24); }
      main.page.woocommerce.front ul.products li.product .image-wrapper img:hover, body.woocommerce.single-product .product .related.products ul.products > li.product .image-wrapper img:hover {
        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23); }
  main.page.woocommerce.front ul.products li.product > a, body.woocommerce.single-product .product .related.products ul.products > li.product > a {
    -ms-flex-positive: 1;
    flex-grow: 1; }
  main.page.woocommerce.front ul.products li.product a, body.woocommerce.single-product .product .related.products ul.products > li.product a {
    display: block; }
  main.page.woocommerce.front ul.products li.product h3, body.woocommerce.single-product .product .related.products ul.products > li.product h3 {
    display: inline-block;
    margin: 0 0 0.5rem;
    color: #1688ec;
    font-size: 2rem;
    font-weight: bold;
    font-family: "Amatic SC", sans-serif;
    transition: all 0.1s ease-in-out; }
    main.page.woocommerce.front ul.products li.product h3:hover, body.woocommerce.single-product .product .related.products ul.products > li.product h3:hover {
      color: #379df7; }
  main.page.woocommerce.front ul.products li.product .author, body.woocommerce.single-product .product .related.products ul.products > li.product .author {
    margin: 0 0 0.5rem;
    font-size: 1.1rem; }
  main.page.woocommerce.front ul.products li.product .description, body.woocommerce.single-product .product .related.products ul.products > li.product .description {
    display: none;
    margin: 0 0 1rem;
    padding: 0.5rem 0.5rem 0 0;
    font-size: 0.8em;
    line-height: 1.2em; }
  main.page.woocommerce.front ul.products li.product .price, body.woocommerce.single-product .product .related.products ul.products > li.product .price {
    display: block;
    margin: 0 0 1rem;
    font-size: 1.6rem; }
    main.page.woocommerce.front ul.products li.product .price del, body.woocommerce.single-product .product .related.products ul.products > li.product .price del {
      color: #5F5F5F;
      opacity: 0.75; }
    main.page.woocommerce.front ul.products li.product .price ins, body.woocommerce.single-product .product .related.products ul.products > li.product .price ins {
      text-decoration: none;
      border-bottom: 1px dotted #5F5F5F; }
  main.page.woocommerce.front ul.products li.product .buttons, body.woocommerce.single-product .product .related.products ul.products > li.product .buttons {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center; }
    @media (max-width: 480px) {
      main.page.woocommerce.front ul.products li.product .buttons, body.woocommerce.single-product .product .related.products ul.products > li.product .buttons {
        -ms-flex-direction: column;
        flex-direction: column; } }
    main.page.woocommerce.front ul.products li.product .buttons .button, body.woocommerce.single-product .product .related.products ul.products > li.product .buttons .button {
      -ms-flex-negative: 0;
      flex-shrink: 0;
      margin: 0 1rem;
      padding: 1rem;
      background-color: #FFF;
      color: #3f3f3f;
      font-size: 1.2rem;
      transition: all 0.2s ease-in-out; }
      @media (max-width: 480px) {
        main.page.woocommerce.front ul.products li.product .buttons .button, body.woocommerce.single-product .product .related.products ul.products > li.product .buttons .button {
          margin: 0.5rem 0; } }
      main.page.woocommerce.front ul.products li.product .buttons .button::before, body.woocommerce.single-product .product .related.products ul.products > li.product .buttons .button::before {
        display: inline-block;
        margin: 0 0.25rem 0 0;
        content: "\f07a";
        color: #CDCDCD;
        font-family: fontawesome;
        transition: all 0.2s ease-in-out; }
      main.page.woocommerce.front ul.products li.product .buttons .button.more-info::before, body.woocommerce.single-product .product .related.products ul.products > li.product .buttons .button.more-info::before {
        content: "\f05a"; }
      main.page.woocommerce.front ul.products li.product .buttons .button::after, body.woocommerce.single-product .product .related.products ul.products > li.product .buttons .button::after {
        display: none; }
      main.page.woocommerce.front ul.products li.product .buttons .button:hover, body.woocommerce.single-product .product .related.products ul.products > li.product .buttons .button:hover {
        background-color: #d59d30;
        color: #FFF;
        border-color: #f2b438; }
        main.page.woocommerce.front ul.products li.product .buttons .button:hover::before, body.woocommerce.single-product .product .related.products ul.products > li.product .buttons .button:hover::before {
          color: #f2b438; }

.woocommerce-message,
.woocommerce-error {
  padding: 0.325rem;
  margin: 0 0 1rem;
  text-align: center;
  border-radius: 3px;
  border: 1px solid #d59d30;
  background-color: rgba(213, 157, 48, 0.2); }
  .woocommerce-message a.button,
  .woocommerce-error a.button, .woocommerce-message button,
  .woocommerce-error button {
    margin: 0 1rem; }

ul.woocommerce-error {
  margin-left: 0;
  margin-right: 0;
  border-color: #fd0100;
  background-color: rgba(253, 1, 0, 0.1); }

.shop_table {
  width: 100%;
  border: 1px solid #d59d30; }
  .shop_table thead th {
    padding: 0.5rem;
    font-weight: bold;
    background-color: rgba(213, 157, 48, 0.2);
    border-bottom: 1px solid rgba(213, 157, 48, 0.3); }
    .shop_table thead th.product-name {
      text-align: left; }
    .shop_table thead th.product-subtotal, .shop_table thead th.product-total {
      text-align: left; }
  .shop_table tbody .cart_item td {
    padding: 1rem;
    text-align: center;
    vertical-align: middle;
    border-bottom: 1px solid rgba(213, 157, 48, 0.2); }
    .shop_table tbody .cart_item td:not(:last-child) {
      border-right: 1px solid rgba(213, 157, 48, 0.1); }
    .shop_table tbody .cart_item td.product-name {
      text-align: left;
      font-size: 1.2rem; }
      .shop_table tbody .cart_item td.product-name a {
        display: block; }
      .shop_table tbody .cart_item td.product-name .variation {
        display: -ms-flexbox;
        display: flex;
        padding: 0.25rem 0.5rem;
        font-size: 1rem;
        line-height: 1rem;
        color: #999; }
        .shop_table tbody .cart_item td.product-name .variation:not(:last-of-type) {
          margin: 0 0 0.5rem; }
        .shop_table tbody .cart_item td.product-name .variation > dt {
          margin: 0 0.5rem 0 0; }
        .shop_table tbody .cart_item td.product-name .variation > dd {
          color: #666; }
        .shop_table tbody .cart_item td.product-name .variation p {
          margin: 0;
          line-height: 1rem; }
    .shop_table tbody .cart_item td.product-subtotal, .shop_table tbody .cart_item td.product-total {
      text-align: left;
      font-size: 1.2rem; }
  .shop_table tfoot tr th {
    padding: 0.5rem;
    text-align: right; }
  .shop_table tfoot tr td {
    padding: 0.5rem; }
    .shop_table tfoot tr td ul, .shop_table tfoot tr td ol {
      margin: 0; }
  .shop_table ul > li::before {
    display: none; }
  .shop_table .cart-subtotal,
  .shop_table .order-total {
    font-size: 1.2rem; }

body.woocommerce-account form.login,
form.lost_reset_password {
  width: 100%;
  max-width: 46rem; }
