body > header.desktop {
	display: flex;
	align-items: center;
	justify-content: space-between;

	border-bottom: 1px solid #000;

	background-image: url(../images/header-bg.jpg);
	background-color: $color-silver;
	background-position: center;
	background-repeat: repeat-x;
	background-size: cover;

	@include with-touch-nav {
		display: none;
	}

	.site-branding {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 1.5rem 0 1.5rem 2rem;

		.logo {
			display: block;
			margin: 0 0 1rem;
			width: 314px;
			height: 86px;

			color: transparent;
			font-size: 0;

			background-image: url(../images/logo.png);
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;

			@media (max-width: 1024px) {
				width: 280px;
				background-position: left center;
			}
		}

		h2 {
			color: #000;
			font-family: $font-title;
			font-size: 2.5rem;
			font-weight: bold;
			text-align: center;

			transition: all 0.2s ease-in-out;
			border-bottom: 1px solid transparent;

			@media (max-width: 1024px) {
				font-size: 2rem;
			}
		}

		.logo:hover + a h2,
		h2:hover {
			border-bottom: 1px solid $color-red;
		}
	}

	nav {
		display: flex;

		background-color: rgba(#000, 0.7);

		div {
			display: flex;
		}

		.menu {
			display: flex;
			padding: 0 1rem;

			&.cart-menu {
				background-color: #000;

				a {
					color: #DDD;

					&:hover {
						color: #FFF;
					}
				}

				.cart-count {
					display: inline-block;
					margin: 0 0 0 0.5rem;

					color: $color-red;

					&::before {
						display: inline-block;
						margin: 0 0.25rem 0 0;

						content: "\f07a";
						font-family: fontawesome;
					}
				}
			}

			> li {
				display: flex;
				margin: 0 1rem;

				@media (max-width: 1024px) {
					margin: 0 0.5rem;
				}

				> a {
					display: block;
					padding: 1.5rem 0 1rem;

					font-family: $font-title;
					font-weight: bold;
					font-size: 2rem;
					color: #FFF;
					text-shadow: 0 1px 2px rgba(#000, 0.5);

					transition: all 0.2s ease-in-out;

					border-bottom: 0.75rem solid transparent;

					&:hover {
						border-bottom: 0.75rem solid $color-mustard;
					}

					@media (max-width: 1024px) {
						font-size: 1.6rem;
					}
				}

				&.current-menu-item {

					> a {
						color: lighten($color-mustard, 8%);

						border-bottom: 0.75rem solid $color-mustard;
					}
				}

				&.facebook a {
					font-size: 0;
					color: transparent;

					&::before {
						display: inline-block;
						padding: 0 0.5rem;

						color: #FFF;
						content: "\f09a";
						font-size: 2rem;
						font-family: fontawesome;

						@media (max-width: 1024px) {
							font-size: 1.6rem;
						}
					}
				}

				> .sub-menu {
					display: none;
				}
			}
		}
	}
}