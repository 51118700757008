body.login {
	background-color: #134282;

	div#login h1 a {
		background-image: url(../avatar.png);
		background-size: contain;
		background-position: center center;
		background-repeat: no-repeat;
		width: 300px;
		height: 100px;
	}
}