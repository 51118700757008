gecko-slide {
	$transition-speed: 1s;

	display: flex;

	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;

	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;

	transition: opacity #{ $transition-speed } ease-in-out;
	opacity: 0;

	&[data-state="in"] {
		z-index: 2;
		opacity: 1;
	}
}
