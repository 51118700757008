body > footer {
	position: relative;

	display: flex;
	justify-content: space-between;
	padding: 3rem 2rem;

	text-align: center;
	color: #FFF;

	@media (max-width: 1024px) {
		flex-direction: column;
		padding: 5rem 2rem 2rem;

		div {
			margin: 0.5rem 0;
		}
	}

	a {
		color: $color-mustard;
	}

	.colophon {
		flex-grow: 1;
		flex-basis: 24rem;

		@media (max-width: 1024px) {
			flex-basis: auto;
		}

		&.left-align {
			text-align: left;

			@media (max-width: 1024px) {
				text-align: center;
			}
		}

		&.right-align {
			text-align: right;

			@media (max-width: 1024px) {
				text-align: center;
			}
		}
	}

	.footer-seal {
		position: absolute;
		left: 50%;
		top: -5.625rem;
		transform: translateX(-50%);

		height: 189px;
		width: 196px;

		background-image: url(../images/footer-seal.png);
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;

		@media (max-width: 1280px) {
			height: 140px;
		}
	}
}



.footer-quote {
	display: flex;
	justify-content: space-between;
	padding: 4rem 2rem;

	background-image: url(../images/footer-quote-bg.jpg);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;

	@media (max-width: 1024px) {
		flex-direction: column;
	}

	> div {
		width: 100%;

		&:first-child {
			margin-right: 100px;

			@media (max-width: 1024px) {
				margin-right: 0;
				margin: 0 0 2rem;
			}
		}

		&:last-child {
			margin-left: 100px;

			@media (max-width: 1024px) {
				margin-left: 0;
				padding: 0 0 2rem;
			}
		}
	}

	blockquote {
		position: relative;

		color: $color-dark-text;
		font-size: 1.8rem;
		line-height: 1.2em;

		@media (max-width: 480px) {
			font-size: 1.4rem;
		}

		&::before {
			position: absolute;
			top: 0;
			right: calc(100% + 1rem);

			display: block;
			height: 5rem;
			width: 5rem;

			content: "";
			background-image: url(../images/quote.svg);
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;

			@media (max-width: 1024px) {
				top: inherit;
				right: inherit;

				position: relative;
				margin: 0 auto;
			}
		}

		cite {
			display: block;
			padding: 0.5rem 0 0 1.5rem;

			font-size: 1.2rem;

			@media (max-width: 480px) {
				padding: 0.5rem 0 0 0;
				font-size: 1rem;
			}

			&::before {
				display: inline-block;
				margin: 0 0.5rem 0 0;
				content: "\2014";
			}
		}
	}

	iframe {
		width: 100%;
	}
}