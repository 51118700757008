@import url(//maxcdn.bootstrapcdn.com/font-awesome/4.6.3/css/font-awesome.min.css);
@import url(https://fonts.googleapis.com/css?family=Amatic+SC:400,700|Antic+Slab);

// Foundation's Reset
// @import "normalize" !not-sassy;

// Constants and colors
@import "constants";
@import "colors";

// Foundation
// @import "lib/foundation" !not-sassy;

// Resets
@import "lib/reset";

@import "utilities/**/*.scss";

// Baseline styling
@import "base";

// Include site components
@import "components/**/*.scss";

// Pages
@import "pages/**/*.scss";
@import "woo/**/*.scss";