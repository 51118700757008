main.page.woocommerce.front {
	padding: 3rem 1rem;

	.page-title {
		color: #FFF;
		font-size: 3rem;
		font-family: $font-title;

		@media (max-width: 640px) {
			text-align: center;
			margin: 0 0 1rem;
		}
	}

	.shop-header {
		display: flex;
		justify-content: space-between;
		margin: 0 0 1rem;
		padding: 0 1rem;

		@media (max-width: 640px) {
			flex-direction: column;
		}

		.sorting {
			display: flex;
			align-items: center;
			color: #FFF;

			> span {

				@media (max-width: 480px) {
					display: none;
				}
			}

			@media (max-width: 480px) {
				flex-direction: column;
			}
		}

		form {
			display: flex;
			align-items: center;
			margin: 0 1rem;
			height: 100%;

			@media (max-width: 640px) {
				flex-grow: 1;
				margin: 0 1rem 0 0;
			}

			@media (max-width: 480px) {
				margin: 0 0 1rem;
			}
		}

		nav {
			display: flex;

			@media (max-width: 480px) {
				display: none;
			}

			a {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 3rem;
				width: 3rem;

				font-size: 1.6rem;
				line-height: 1rem;
				color: $color-mustard;

				transition: all 0.2s ease-in-out;

				border: 2px solid $color-mustard;

				&:not(:last-of-type) {
					margin: 0 1rem 0 0;
				}

				&::before {
					display: inline-block;
					font-family: fontawesome;
					transition: all 0.2s ease-in-out;
					-webkit-font-smoothing: antialiased;
				}

				&.grid::before { content: "\f00a"; }
				&.list::before { content: "\f00b"; }

				&[data-status="active"] {
					color: #FFF;

					cursor: default;
					background-color: $color-mustard;

					&:hover {
						background-color: $color-mustard;

						&::before {
							color: #FFF;
						}
					}
				}

				&:hover {
					background-color: rgba($color-mustard, 0.15);

					&::before {
						color: $color-mustard-hl;
					}
				}
			}
		}
	}

	ul.products {
		display: flex;
		flex-wrap: wrap;

		li.product {
			@extend %product-tile;
			padding: 2rem;
			flex-basis: 20rem;
		}

		&[data-view="list"] {
			flex-direction: column;

			max-width: $row-width;
			margin: 0 auto;

			li.product {
				flex-basis: auto;
				flex-direction: row;

				@media (max-width: 640px) {
					flex-direction: column;
				}

				.image-wrapper {
					margin: 0 2rem 0 0;
					width: 12rem;
					height: 12rem;

					@media (max-width: 640px) {
						margin: 0 0 1rem;
						width: 100%;
					}

					img {
						max-height: 100%;
						width: auto;
					}
				}

				.description {
					display: block;
				}

				.item-meta {
					width: 100%;
					text-align: left;

					@media (max-width: 640px) {
						text-align: center;
					}
				}

				> a {
					display: flex;
					align-items: center;

					@media (max-width: 640px) {
						display: block;
					}

					h3 {
						border-bottom: 1px solid transparent;
					}

					&:hover h3 {
						border-bottom: 1px solid $color-blue;
					}
				}

				.buttons {
					flex-shrink: 0;
					display: flex;
					flex-direction: column;
					align-items: center;

					.button {
						margin: 0.5rem 0;
						width: 100%;
					}
				}
			}
		}
	}
}


.woocommerce-pagination {
	padding: 2rem 0 0;

	.page-numbers {
		display: flex;
		justify-content: center;

		li {

			* {
				display: block;
				margin: 0 0.5rem;
				padding: 1rem;

				color: #FFF;
				font-size: 1.6rem;
				font-family: $font-title;
				font-weight: bold;
			}

			span {
				color: rgba($color-silver, 0.8);
				cursor: default;
				background-color: rgba($color-silver, 0.2);
			}

			a {
				background-color: $color-mustard;
			}
		}
	}
}