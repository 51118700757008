$color-scorpion: #5F5F5F;
$color-coal: #3f3f3f;
$color-silver: #CDCDCD;

$color-mustard: #d59d30;
$color-mustard-hl: #f2b438;
$color-mustard-light: #fff2d9;

$color-red: #fd0100;

$color-blue: #1688ec;
$color-blue-hl: #379df7;


$color-primary: $color-mustard;
$color-text: $color-scorpion;
$color-dark-text: $color-coal;


$color-woo-border: $color-mustard;