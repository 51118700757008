body.woocommerce-cart {

	> h1 {
		display: none;
	}

	.shop_table {

		tbody .cart_item {

			.remove {
				display: flex;
				margin: 0 auto;
				align-items: center;
				justify-content: center;
				height: 3rem;
				width: 3rem;

				text-align: center;
				font-size: 3rem;
				line-height: 3rem;

				color: $color-woo-border;

				border-radius: 3px;

				&:hover {
					background-color: $color-woo-border;
					color: $color-text;
				}
			}

			.product-thumbnail {

				a {
					display: inline-block;
					padding: 0.25rem;

					border-radius: 3px;
					border: 1px solid $color-woo-border;

					background-color: #FFF;

					img {
						display: block;
						margin: 0 auto;
					}
				}
			}
		}

		.actions {
			position: relative;
			// display: flex;
			// width: 100%;
			// padding: 0.5rem;

			.button {
				@extend %button;

				margin-bottom: 0;
			}

			> .coupon {
				// float: left;
				display: flex;
				// align-items: stretch;
				// margin: 0 2rem 0 0;

				label {
					display: none;
				}

				input[type="text"] {
					width: auto;
				}
			}

			input[name="update_cart"].button {
				position: absolute;
				top: 0;
				right: 0;
				// margin-bottom: 0;
				margin-right: 0;
				// float: right;
			}
		}
	}	

	.cart-collaterals {
		// border: 1px solid red;
		padding: 0.5rem 0 0;

		h2 {
			display: none;
		}

		.shop_table {
			border: 0;

			tbody tr {

				th {
					padding: 0.5rem;
					text-align: right;
				}

				td {
					padding: 0.5rem;
					width: 24rem;

					ul, ol {
						margin: 0;
					}
				}
			}
		}

		.wc-proceed-to-checkout {
			text-align: right;

			.button {
				@extend %button;
			}
		}
	}
}