/* Base Styles
 * The styles that go here should be site-global and not specific to any individual components.
 */

body {
	font-family: $font-body;
	font-size: 1.25rem;

	background-color: $color-scorpion;
}


%button {
	display: inline-block;
	margin: 0 0 1rem;
	padding: 1rem 3rem 1rem 3.5rem;

	text-transform: uppercase;
	text-decoration: none;
	color: #FFF;
	font-size: 1.6rem;
	font-weight: bold;
	font-family: $font-title;
	letter-spacing: 1px;

	transition: all 0.2s ease-in-out;
	border: 1px solid $color-mustard;
	background-color: $color-mustard;

	&::after {
		display: inline-block;
		margin: 0 0 0 0.5rem;
		padding: 0 0.5rem 0 0;

		content: "\f105";
		color: rgba($color-mustard-hl, 0.8);
		font-family: fontawesome;

		transition: all 0.2s ease-in-out;
	}

	&:hover {
		background-color: $color-mustard-hl;

		&::after {
			padding: 0;
			margin: 0 0 0 1rem;
			color: lighten($color-mustard-hl, 10%);
		}
	}

	&:not(:last-of-type) {
		margin-right: 1rem;
		margin-bottom: 1rem;
	}

	&.secondary {
		background-color: #FFF;
		color: $color-dark-text;

		&::after {
			color: lighten($color-silver, 8%);
		}

		&:hover {
			background-color: $color-mustard-light;
		}
	}
}

.button {
	@extend %button;
}



hr {
	display: block;
	margin: 3.5rem 0;

	border: 0;
	border-bottom: 1px solid $color-red;
}


%dummy {
	padding: 0;
	margin-top: 0;
	margin-bottom: 0;
	height: 0;

	visibility: hidden;
}