/* Grid Box (1.0.0)
 * Creates a variable-count and variable-gutter grid system.
 * Apply this class to all children and ensure that they are the only members of the grid container.
 * This system is much more flexible than Foundation, it should be used for grids instead of columns.
 */
@mixin grid-box($count, $spacing:10px, $vspacing:$spacing) {
	vertical-align: top;
	display: inline-block;

	width: calc(#{100% / $count} - #{$spacing});
	margin-right: $spacing;
	margin-bottom: $vspacing;

	&:nth-of-type(#{$count}n + #{$count}) {
		margin-right: 0;
	}
}