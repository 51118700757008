.chosen-container {
	position: relative;

	display: inline-block;
	vertical-align: middle;

	font-size: 1rem;
	text-align: left;
	user-select: none;

	.chosen-drop {
		position: absolute;
		z-index: 1010;
		top: 100%;
		left: -9999px;

		width: 100%;

		background: darken($color-scorpion, 3%);
		@include material-shadow(2);
	}

	&.chosen-with-drop .chosen-drop {
		left: 0;
	}

	a {
		cursor: pointer;
	}

	.search-choice .group-name,
	.chosen-single .group-name {
		margin-right: 4px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		font-weight: normal;
		color: #999999;

		&::after {
			content: ":";
			padding-left: 2px;
			vertical-align: top;
		}
	}
}




/* @end */
/* @group Single Chosen */
.chosen-container-single {
	height: 3rem;

	.chosen-single {
		position: relative;
		display: flex;
		align-items: center;
		overflow: hidden;
		padding: 0 0 0 8px;
		height: 100%;
		background: $color-scorpion;
		background-clip: padding-box;
		color: #FFF;
		text-decoration: none;
		white-space: nowrap;
		line-height: 2rem;

		span {
			display: block;
			overflow: hidden;
			padding-left: 6px;
			margin-right: 26px;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		abbr {
			position: absolute;
			top: 6px;
			right: 26px;
			display: block;
			width: 12px;
			height: 12px;
			// background: url('../images/chosen-sprite.png') -42px 1px no-repeat;
			font-size: 1px;

			&:hover {
				background-position: -42px -10px;
			}
		}

		div {
			position: absolute;
			top: 0;
			right: 0;
			display: block;
			width: 22px;
			height: 100%;

			b {
				display: flex;
				align-items: center;
				width: 100%;
				height: 100%;

				&::before {
					color: $color-mustard;
					content: "\f0d7";
					font-family: fontawesome;
				}
			}
		}
	}

	&.chosen-disabled .chosen-single abbr:hover {
		background-position: -42px -10px;
	}

	.chosen-single-with-deselect span {
		margin-right: 38px;
	}

	// .chosen-default {
	// 	color: #999;
	// }

	.chosen-search {
		position: relative;
		z-index: 1010;
		margin: 0;
		padding: 3px 4px;
		white-space: nowrap;

		input[type="text"] {
			margin: 1px 0;
			padding: 4px 20px 4px 5px;
			width: 100%;
			height: auto;
			outline: 0;
			border: 1px solid #aaa;
			background: $color-scorpion;
			font-size: 1em;
			font-family: sans-serif;
			line-height: normal;
			border-radius: 0;
		}
	}

	&.chosen-container-single-nosearch .chosen-search {
		position: absolute;
		left: -9999px;
	}

	.chosen-drop {
		margin-top: -1px;
		border-radius: 0;
		background-clip: padding-box;
	}
}


/* @end */
/* @group Results */
.chosen-container .chosen-results {
	position: relative;
	overflow-x: hidden;
	overflow-y: auto;

	max-height: 240px;

	color: #EEE;

	-webkit-overflow-scrolling: touch;

	li {
		display: none;
		margin: 0;
		padding: 0.625rem 1rem;

		list-style: none;
		line-height: 1rem;
		word-wrap: break-word;
		-webkit-touch-callout: none;

		&.active-result {
			display: list-item;
			cursor: pointer;
		}

		&.result-selected {
			display: list-item;
			color: #9A9A9A;
			cursor: default;
		}

		&.disabled-result {
			display: list-item;
			color: #EEE;
			cursor: default;
		}

		&.highlighted {
			background-color: lighten($color-scorpion, 5%);
			color: #FFF;
		}

		&.no-results {
			padding: 1rem;
			display: list-item;
			background: $color-scorpion;
			color: #EEE;

			span {
				color: $color-red;
			}
		}

		&.group-result {
			display: list-item;
			font-weight: bold;
			cursor: default;
		}

		&.group-option {
			padding-left: 15px;
		}

		em {
			font-style: normal;
			text-decoration: underline;
		}
	}
}

/* @end */
/* @group Multi Chosen */
.chosen-container-multi .chosen-choices {
	position: relative;

	overflow: hidden;

	margin: 0;
	padding: 0 1.75rem 0.5rem 0.5rem;
	width: 100%;
	height: auto;
	min-height: 100%;

	border: 0;
	background-color: $color-mustard;
	cursor: pointer;

	&::after {
		position: absolute;
		top: 10px;
		right: 10px;
		// transform: translateY(-50%);

		content: "\f0d7";
		color: $color-silver;
		font-size: 1.5rem;
		font-family: fontawesome;

		cursor: pointer;
	}

	li {
		float: left;
		list-style: none;

		&.search-field {
			margin: 0;
			padding: 0;
			white-space: nowrap;

			input[type="text"] {
				display: inline-block;
				margin: 0.375rem 0 0;
				padding: 0.5rem 0 0;
				outline: 0;

				background: transparent;

				color: #999;
				font-size: 100%;
				font-family: sans-serif;
				line-height: normal;

				border: 0;
				box-shadow: none;
				border-radius: 0;
				cursor: pointer;
			}
		}

		&.search-choice {
			position: relative;

			margin: 0.5rem 0.5rem 0 0;
			padding: 0.5rem 1.5rem 0.5rem 0.5rem;
			max-width: 100%;

			background-color: $color-red;

			color: #EEE;
			line-height: 13px;

			// cursor: default;

			span {
				word-wrap: break-word;
			}

			.search-choice-close {
				position: absolute;
				right: 6px;
				top: 9px;

				display: block;
				width: 12px;
				height: 12px;

				background: url('../images/chosen-sprite.png') -42px 1px no-repeat;
				font-size: 1px;

				&:hover {
					background-position: -42px -10px;
				}
			}
		}

		&.search-choice-disabled {
			padding-right: 0.5rem;
			background: transparent;
			text-transform: uppercase;
			color: #DDD;
		}

		&.search-choice-focus {
			background: #d4d4d4;

			.search-choice-close {
				background-position: -42px -10px;
			}
		}
	}
}


/* @end */
/* @group Active  */
.chosen-container-active .chosen-single {
	
}
.chosen-container-active.chosen-with-drop .chosen-single {

	div {
		border-left: none;
		background: transparent;

		b {
			background-position: -18px 2px;
		}
	}
}

/* @end */
/* @group Disabled Support */
.chosen-disabled {
	opacity: 0.5;
	cursor: default;

	.chosen-single,
	.chosen-choices .search-choice .search-choice-close {
		cursor: default;
	}
}