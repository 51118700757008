// Add this class or pseudoclass to any content generated by WordPress.
// Additionally, it can be used when you want to ensure consistent styling.

// Edit + remove styles here freely.

%wysiwyg, .wysiwyg {
	color: #FFF;

	h1, h2, h3, h4, h5, h6 {
		margin: 0 0 2rem;

		line-height: 1.2em;
		font-family: $font-title;
	}

	h1 {
		font-size: 3.5rem;
	}

	h2 {
		font-size: 3.125rem;
	}

	h3 {
		font-size: 2.625rem;
	}

	h4 {
		font-size: 2.25rem;
	}

	h5 {
		font-size: 1.875rem;
	}

	h6 {
		font-size: 1.625rem;
	}

	p {
		line-height: 1.3;
		margin: 1rem 0;
	}

	b, strong {
		font-weight: bold;
	}

	i, em {
		font-style: italic;
	}

	ul {
		list-style: none;
		margin: 0 0 2rem 1.5rem;

		> li ul {
			margin: 0 0 0 1.5rem;
		}

		li {
			position: relative;
			line-height: 1.2rem;

			&::before {
				position: absolute;
				top: 0;
				right: 100%;

				display: block;

				height: 2rem;
				width: 2rem;

				content: "\f105";
				color: $color-blue;
				text-align: center;
				font-size: 1.2rem;
				line-height: 1rem;
				font-family: fontawesome;
			}
		}
	}

	ol {
		counter-reset: ol;

		> li {
			counter-increment: ol;

			&::before {
				content: counter(ol);
				margin-right: 0.5rem;
				color: $color-blue;
			}
		}

		ol {
			margin: 0 0 0 1.5rem;
		}
	}

	ul, ol {

		> li {
			margin: 0.75rem 0;
		}
	}

	a {
		color: $color-mustard;
	}

	button,
	.button,
	input[type="button"],
	input[type="submit"] {
		@extend %button;
	}

	blockquote {
		cite {
		}
	}
}