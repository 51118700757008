/* Full-width mixin (1.0.0)
 * Creates a full-width section
 * Side-effect: element must be position relative
 */

@mixin full-width {
	position: relative;

	&::before {
		z-index: -1;
		content: "";
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100%;

		@media screen and (min-width: #{$row-width}) {
			left: calc(-50vw + #{$row-width} / 2);
		}

		@content;
	}
}