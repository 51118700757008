main.page {
	padding: 2rem 0 4rem;

	background-image: url(../images/triangles-bg.jpg);
	background-color: #333;

	.wysiwyg {
		padding: 2rem;

		@media (max-width: 640px) {
			padding: 2rem 1rem;
		}
	}
}