body > header.touch {
	display: none;

	@include with-touch-nav {
		display: flex;
	}

	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;

	width: 100%;

	color: #FFF;
	background-color: $color-scorpion;

	// The menu toggle button
	> .toggle-touch-menu {
		flex-shrink: 0;

		display: inline-flex;
		justify-content: center;
		align-items: center;

		text-align: center;
		font-size: 2.5rem;
		padding: 0.2em;
		cursor: pointer;

		&::before {
			font-family: FontAwesome;
			width: 1em;
			height: 1em;
			vertical-align: top;
			content: "\f0c9";
			-webkit-font-smoothing: antialiased;
			color: $color-mustard;
		}
	}

	> .logo {
		display: flex;
		align-items: center;

		a {
			display: block;
			padding: 0.5rem;

			text-align: right;
			font-size: 1.8rem;
			font-family: $font-title;
		}
	}
}