select {
	padding: 0.5rem;
	color: #FFF;
	background-color: $color-scorpion;
}

%text-input {
	display: block;
	padding: 0.5rem;
	width: 100%;

	font-size: 1.2em;
	border: 0;
	box-shadow: none;
	outline: none;

	background-color: $color-mustard-light;

	transition: all 0.2s ease-in-out;

	&:focus {
		background-color: #FFF;
	}
}
textarea,
input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]) {
	@extend %text-input;
}



input[type="radio"] {
	display: inline-block;
	margin: 0 0.5rem 0 0;
}



$color-error: $color-red;
%gform-error {
	background-color: rgba($color-error, 0.1);
	border: 1px solid rgba($color-error, 0.25);
	color: $color-error;
}


$bp2: 640px;

.wysiwyg .gform_wrapper,
.gform_wrapper {

	ul,
	.chosen-results {
		list-style: none;
		margin: 0;

		li::before {
			display: none;
		}
	}

	.gform_heading {
		margin: 0 0 1rem;

		.gform_title {
			display: block;
			margin: 0 0 0.25rem;

			font-size: 2rem;
			color: $color-blue;
		}

		.gform_description {
			font-size: 0.9em;
		}
	}

	.validation_error {
		margin: 0 0 1rem;
		padding: 1rem;

		color: $color-error;
		background-color: rgba($color-error, 0.1);
		border-radius: 3px;
		border: 1px solid $color-error;
	}

	.gform_body .gform_fields {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -1rem;
		list-style: none;

		.gfield {
			margin: 0 0 1.5rem;
			padding: 0 1rem;
			width: 100%;

			&.half-col {
				width: 50%;

				@media (max-width: $bp2) {
					width: 100%;
				}
			}

			&.third-col {
				width: 33.3%;

				@media (max-width: $bp2) {
					width: 100%;
				}
			}

			&.gfield_visibility_ {
				margin-top: 0;
				margin-bottom: 0;
				height: 0;
				overflow: hidden;
				visibility: hidden;
			}

			.gfield_label {
				display: block;
				margin: 0 0 0.5rem;

				font-family: $font-title;
				font-size: 1.8rem;
				text-transform: uppercase;

				.gfield_required {
					display: inline-block;
					margin: 0 0 0 0.125rem;

					color: $color-error;
				}
			}

			.validation_message {
				padding: 0.4rem 0.4rem 0.2rem;

				background-color: rgba($color-error, 0.15);
				border-radius: 0 0 3px 3px;
				border: 1px solid $color-error;
				border-top: 0;

				color: $color-error;
				font-size: 0.7em;
				text-transform: uppercase;
			}

			.ginput_container_address {
				display: flex;
				flex-wrap: wrap;
				margin: 0 -0.5rem;

				span {
					display: block;
					padding: 0 0.5rem;
					margin: 0 0 0.5rem;
					width: 100%;

					&.ginput_left,
					&.ginput_right {
						width: 50%;

						@media (max-width: $bp2) {
							width: 100%;
						}
					}

					label {
						text-transform: uppercase;
						font-size: 0.6em;
					}
				}
			}

			li {
				line-height: 1.5em;
			}

			&.gfield_error {

				input {
					background-color: rgba($color-error, 0.1);
					border-color: $color-error;
					border-bottom: 0;
					border-radius: 3px 3px 0 0;
				}
			}
		}
	}

	.gform_footer {
		position: relative;

		text-align: center;

		.button {
			outline: none;
			padding: 1rem 3.5rem;
		}

		.gform_ajax_spinner {
			position: absolute;
			top: 50%;
			right: 0;

			transform: translateY(-50%);

			background-size: contain;
			background-position: center;
		}
	}
}