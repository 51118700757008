body > aside.touch-menu {
	position: fixed;
	top: 0;
	left: 0;

	z-index: 105;
	width: 80vw;
	max-width: 25rem;
	height: 100vh;

	display: flex;
	flex-direction: column;

	color: #FFF;
	font-family: $font-title;
	font-size: 2rem;

	background-color: $color-scorpion;

	transition: left 0.3s ease-in-out;

	@include material-shadow(3);

	&[hidden] {
		left: -100%;
	}

	nav {
		flex-grow: 1;
		overflow-y: auto;

		li {
			display: block;

			a {
				display: block;

				padding: 1rem;

				border-bottom: 1px solid darken($color-scorpion, 5%);

				transition: background-color 0.2s ease-in-out;

				&:focus, &:hover {
					background-color: rgba(black, 0.2);
				}

				&:active {
					background-color: rgba(black, 0.3);
				}
			}
		}
	}

	footer {
		flex-shrink: 0;

		padding: 1rem;

		text-align: center;

		background-color: #000;

		a {
			color: #DDD;

			&:hover {
				color: #FFF;
			}
		}

		.cart-count {
			display: inline-block;
			margin: 0 0 0 0.5rem;

			color: $color-red;

			&::before {
				display: inline-block;
				margin: 0 0.25rem 0 0;

				content: "\f07a";
				font-family: fontawesome;
			}
		}
	}
}