main.page.front {
	padding: 4rem 0;
}

.featured-product {
	position: relative;

	padding: 2rem;

	background-color: #FFF;

	&::after {
		position: absolute;
		z-index: 10;
		top: 0;
		right: 0;

		display: block;
		height: 100%;
		width: 55%;

		content: "";

		background-image: url(../images/featured-product-bg.jpg);
		background-position: center right;
		background-repeat: no-repeat;
		background-size: cover;

		@media (max-width: 1024px) {
			top: 50%;
			width: 100%;
			height: 50%;

			background-image:  linear-gradient(to bottom, #FFF, rgba(#FFF, 0.6) 40%, rgba(#FFF, 0)), url(../images/featured-product-bg.jpg);
		}
	}

	.content {
		position: relative;
		z-index: 20;
		width: 40%;

		@media (max-width: 1024px) {
			width: 100%;
		}

		.title {
			display: inline-block;
			margin: 0 0 1rem;

			color: $color-blue;
			font-family: $font-title;
			font-weight: bold;
			font-size: 4rem;

			border-bottom: 1px solid transparent;

			transition: all 0.2s ease-in-out;

			&:hover {
				border-bottom: 1px solid $color-blue;
			}
		}

		p {
			margin: 0 0 1.5rem;

			color: $color-text;
			font-size: 1.2rem;
			line-height: 1.3em;
		}

		.cta-buttons {


		}
	}
}