%product-tile {
	flex-grow: 1;
	// flex-basis: 20rem;

	display: flex;
	flex-direction: column;
	margin: 1rem;
	// padding: 2rem;

	text-align: center;
	color: $color-dark-text;

	background-color: $color-silver;
	transition: background-color 0.4s ease-in-out;

	@include material-shadow(1);

	&:hover {
		background-color: lighten($color-silver, 3%);
	}

	&.dummy {
		@extend %dummy;
	}

	.image-wrapper {
		position: relative;

		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 0 0.5rem;
		height: 20rem;
		max-width: 100%;

		.onsale {
			position: absolute;
			bottom: 0;
			right: 0;

			padding: 0.5rem;

			color: #FFF;
			font-family: $font-title;
			font-size: 2rem;

			border-radius: 1000px;
			@include material-shadow(1);

			background-color: $color-red;
		}

		img {
			display: block;
			height: auto;
			max-width: 100%;

			transition: all 0.1s ease-in-out;
			@include material-shadow(1);

			&:hover {
				@include material-shadow(2);
			}
		}
	}

	> a {
		flex-grow: 1;
	}

	a {
		display: block;
	}

	h3 {
		display: inline-block;
		margin: 0 0 0.5rem;

		color: $color-blue;
		font-size: 2rem;
		font-weight: bold;
		font-family: $font-title;

		transition: all 0.1s ease-in-out;

		&:hover {
			color: $color-blue-hl;
		}
	}

	.author {
		margin: 0 0 0.5rem;
		font-size: 1.1rem;
	}

	.description {
		display: none;
		margin: 0 0 1rem;
		padding: 0.5rem 0.5rem 0 0;
		font-size: 0.8em;
		line-height: 1.2em;
	}

	.price {
		display: block;
		margin: 0 0 1rem;

		font-size: 1.6rem;

		del {
			color: $color-text;
			opacity: 0.75;
		}

		ins {
			text-decoration: none;
			border-bottom: 1px dotted $color-text;
		}
	}

	.buttons {
		display: flex;
		// flex-wrap: wrap;
		justify-content: center;

		@media (max-width: 480px) {
			flex-direction: column;
		}

		.button {
			flex-shrink: 0;

			margin: 0 1rem;
			padding: 1rem;

			background-color: #FFF;
			color: $color-dark-text;
			font-size: 1.2rem;

			transition: all 0.2s ease-in-out;

			@media (max-width: 480px) {
				margin: 0.5rem 0;
			}

			&::before {
				display: inline-block;
				margin: 0 0.25rem 0 0;

				content: "\f07a";
				color: $color-silver;
				font-family: fontawesome;

				transition: all 0.2s ease-in-out;
			}

			&.more-info::before {
				content: "\f05a";
			}

			&::after { display: none; }

			&:hover {
				background-color: $color-mustard;
				color: #FFF;

				border-color: $color-mustard-hl;

				&::before {
					color: $color-mustard-hl;
				}
			}
		}
	}
}


%woo-message {
	padding: 0.325rem;
	margin: 0 0 1rem;

	text-align: center;

	border-radius: 3px;
	border: 1px solid $color-woo-border;

	background-color: rgba($color-woo-border, 0.2);

	a.button, button {
		margin: 0 1rem;
	}
}
.woocommerce-message,
.woocommerce-error {
	@extend %woo-message;
}

ul.woocommerce-error {
	margin-left: 0;
	margin-right: 0;

	border-color: $color-error;
	background-color: rgba($color-error, 0.1);
}

.shop_table {
	// margin: 0 0 1rem;
	width: 100%;

	border: 1px solid $color-woo-border;

	thead th {
		padding: 0.5rem;

		font-weight: bold;
		background-color: rgba($color-woo-border, 0.2);
		border-bottom: 1px solid rgba($color-woo-border, 0.3);

		&.product-name {
			text-align: left;
		}

		&.product-subtotal,
		&.product-total {
			text-align: left;
		}
	}

	tbody .cart_item {
		// border: 1px solid red;

		td {
			padding: 1rem;

			text-align: center;
			vertical-align: middle;

			border-bottom: 1px solid rgba($color-woo-border, 0.2);

			&:not(:last-child) {
				border-right: 1px solid rgba($color-woo-border, 0.1);
			}

			&.product-name {
				text-align: left;
				font-size: 1.2rem;

				a {
					display: block;
				}

				.variation {
					display: flex;
					padding: 0.25rem 0.5rem;

					font-size: 1rem;
					line-height: 1rem;
					color: #999;

					&:not(:last-of-type) {
						margin: 0 0 0.5rem;
					}

					> dt {
						margin: 0 0.5rem 0 0;
					}

					> dd {
						color: #666;
					}

					p {
						margin: 0;
						line-height: 1rem;
					}
				}
			}

			&.product-subtotal,
			&.product-total {
				text-align: left;
				font-size: 1.2rem;
			}
		}
	}

	tfoot tr {

		th {
			padding: 0.5rem;
			text-align: right;
		}

		td {
			padding: 0.5rem;

			ul, ol {
				margin: 0;
			}
		}
	}

	ul > li::before {
		display: none;
	}

	.cart-subtotal,
	.order-total {
		font-size: 1.2rem;
	}
}


body.woocommerce-account form.login,
form.lost_reset_password {
	width: 100%;
	max-width: #{$row-width / 2};
}